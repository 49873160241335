
import { mapStateTyped, mapMutationsTyped } from '@/store'
import Vue from 'vue'
import OverlayPanel from 'primevue/overlaypanel'
import { te } from '@/translations'

export default Vue.extend({
  name: 'LayersMenu',
  components: { OverlayPanel },
  computed: {
    ...mapStateTyped(['spaProps', 'currentLayerName']),
    layersButtons(): string[] {
      return this.spaProps.layers
        ? ['default', ...this.spaProps.layers.map(({ title }) => title)]
        : []
    }
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
    te,
    menuToggle(ref: string, event: EventInit) {
      // @ts-ignore
      this.$refs[ref].toggle(event)
    },
    setLayerName(name: string) {
      localStorage.setItem('savedLayerName', name)
      this.setValue(['currentLayerName', name])
    }
  }
})
