var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('TMarker',{style:({ 'z-index': _vm.complexObject.id === _vm.currentEntityId ? 3 : 1 }),attrs:{"centerizeOnClick":"","id":_vm.complexObject.id,"color":_vm.complexObject.iconColor || '#005AD1',"clusterName":_vm.clusterName,"icon":_vm.complexObject.iconClass,"tooltip":_vm.complexObject.title,"isVisible":_vm.isMarkerOfCollapsedCOVisible,"location":_vm.locationToCoordinates(_vm.complexObject.location),"transparent":_vm.isTransparent,"isPolygonDataLoaded":_vm.polygonData !== null},on:{"click":function($event){return _vm.$store.commit('setValue', ['currentEntityId', _vm.complexObject.id])}},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_c('div',{staticClass:"popup"},[(
            _vm.complexObject.type === 'ComplexObject' || _vm.complexObject.type === 'TboContainerArea'
          )?_c('ComplexObjectCard',{attrs:{"complexObject":_vm.complexObject,"options":{
            actionLabel: _vm.te('open'),
            actionCallback: _vm.openComplexObject,
            isCompactView: true,
            isMapView: true,
            toast: _vm.$toast
          }}}):_vm._e()],1)]},proxy:true}])}),_c('TMarker',{attrs:{"tallLeg":"","isVisible":_vm.isMarkerOfExpandedCOVisible,"id":_vm.complexObject.id,"icon":_vm.complexObject.iconClass,"color":_vm.complexObject.iconColor || '#005AD1',"isPolygonDataLoaded":_vm.polygonData !== null,"transparent":_vm.isTransparent,"tooltip":_vm.complexObject.title,"location":_vm.locationToCoordinates(_vm.complexObject.location)},on:{"click":_vm.openComplexObject}}),_c('TMarker',{attrs:{"type":"label","cursor":"pointer","isVisible":_vm.isLabelOfCOVisible && _vm.complexObjectMarkerType && _vm.complexObjectMarkerType.includes('marker'),"id":_vm.complexObject.id,"title":_vm.complexObject.title,"isPolygonDataLoaded":_vm.polygonData !== null,"location":_vm.locationToCoordinates(_vm.complexObject.location),"transparent":_vm.isTransparent},on:{"click":_vm.openComplexObject}}),_vm._l((_vm.innerComplexObjects),function(innerComplexObject){return _c('div',{key:`innerComplexObject_${innerComplexObject.id}`},[_c('ComplexObjectOnMap',{attrs:{"complexObject":innerComplexObject,"parentExpandingZoom":_vm.expandingZoom,"activeFilters":_vm.activeFilters,"isParentFiltered":_vm.filteredEntitiesIds.has(_vm.complexObject.id)}})],1)}),(_vm.polygonData)?_c('div',[_c('TPolygon',{attrs:{"visible":!_vm.loading && _vm.isCOOutlineVisible,"points":_vm.polygonData.outline ? _vm.polygonData.outline.border : [],"zIndex":2,"options":_vm.complexObjectBaseOption,"minZoom":_vm.expandingZoom},on:{"mouseenter":function($event){_vm.showTooltip = true},"mouseout":function($event){_vm.showTooltip = false},"dblclick":function($event){;(!_vm.isComplexObjectSelected || !_vm.isRoot) &&
        _vm.currentOpenedComplexObject.id != _vm.complexObject.id
          ? _vm.openComplexObject()
          : () => {}}}})],1):_vm._e(),(_vm.isOpened)?_c('div',[_vm._l((_vm.rooms),function(room){return _c('TMarker',{key:`label_${room.id}`,attrs:{"type":"label","title":room.title.length > _vm.maxChars ? room.title.slice(0, _vm.maxChars) + '...' : room.title,"location":_vm.locationToCoordinates(room.location)},on:{"click":function($event){return _vm.clickOnRoomLabel(room)}}})}),_vm._l((_vm.constructionsLabels),function({ id, label, position }){return _c('TMarker',{key:`label_${id}`,attrs:{"type":"label","title":label,"location":position}})}),_vm._l((_vm.rooms),function(room){return _c('div',{key:`markers_${room.id}`},[_c('TPolygon',{attrs:{"points":room.outline.border,"zIndex":2,"options":{
          symbol: {
            lineColor: _vm.highlightedColor(room),
            lineWidth: 1,
            polygonFill: _vm.highlightedColor(room),
            polygonOpacity: 0.2,
            lineJoin: 'round',
            lineDasharray: [10, 10],
            opacity: _vm.clickedOn.includes(room.id) ? 1 : 0.6
          }
        }},on:{"click":function($event){_vm.clickedOn = [room.id]}}}),_vm._l((room.childrens),function(roomEntity){return _c('div',{key:`bolid_${roomEntity.id}`},[_c('TMarker',{attrs:{"type":"camera","id":roomEntity.id,"isActive":true,"location":_vm.locationToCoordinates(roomEntity.location)},on:{"click":function($event){return _vm.setCamera(room.id, roomEntity.id)}}}),(roomEntity.location && roomEntity.directionMinClock !== null)?_c('div',[_c('TSector',{attrs:{"center":_vm.locationToCoordinates(roomEntity.location),"zIndex":3,"radius":2303.14 * Math.pow(0.76, _vm.currentZoom),"startAngle":roomEntity?.directionMaxClock,"endAngle":roomEntity?.directionMinClock,"options":{
              symbol: {
                lineWidth: 1,
                lineColor: 'rgba(60, 114, 255, 1)',
                polygonFill: {
                  type: 'linear',
                  colorStops: _vm.clickedOn.includes(roomEntity.id)
                    ? [
                        [1, 'rgba(60, 114, 255, 0.4)'],
                        [0, 'rgba(60, 114, 255, 1)']
                      ]
                    : [
                        [1, 'rgba(64, 73, 81, 0.1)'],
                        [0, 'rgba(60, 114, 255, 0.5)']
                      ]
                }
              }
            }},on:{"click":() => {
                _vm.clickedOn = [room.id, roomEntity.id]
              }}})],1):_vm._e()],1)})],2)}),_vm._l((_vm.currentFeatureCollections),function(currentFeatureCollection){return _c('TGeoJSON',{key:currentFeatureCollection.id,attrs:{"json":currentFeatureCollection,"zIndex":1,"symbol":{
        lineColor: 'rgb(149, 157, 168)',
        opacity: 0.6,
        lineWidth: 1
      }}})})],2):_vm._e(),(_vm.isOpened)?_c('div',[_c('div',{staticClass:"complex-object-header-panel",style:(_vm.overlayStyle),attrs:{"id":`complex-object-overlay${_vm.complexObject.id}`}},[(!_vm.spaProps.onlyComplexObject || !_vm.isRoot)?_c('Button',{staticClass:"p-button-outlined p-button-rounded p-button-secondary p-mr-6",staticStyle:{"flex-shrink":"0","height":"48px","width":"48px"},attrs:{"icon":"mdi mdi-18px mdi-arrow-left"},on:{"click":_vm.closeComplexObject}}):_vm._e(),_c('div',{staticClass:"p-d-flex p-ai-center",staticStyle:{"cursor":"pointer"},on:{"click":_vm.flyToDefault}},[_c('MarkerCircle',{attrs:{"id":_vm.complexObject.id,"color":_vm.complexObject.iconColor || '#005AD1',"haveLeg":false}},[_c('div',{staticClass:"marker-circle-content"},[_c('i',{staticClass:"mdi mdi-24px",class:_vm.complexObject.iconClass,staticStyle:{"color":"white"}})])]),_c('div',{staticClass:"complex-object-title p-d-flex p-ai-center p-ml-3 p-raised"},[_c('span',{domProps:{"textContent":_vm._s(_vm.complexObject.title)}}),(_vm.complexObject.childrens && _vm.complexObject.childrens.length)?_c('CreateLayoutButton',{staticClass:"p-ml-2",attrs:{"ids":_vm.collectChildrensNodes(_vm.complexObject.childrens),"title":_vm.complexObject.title,"message":"message.createComplexObjectLayout","tooltip":_vm.te('button.createCamerasLayout'),"iconOnly":""},on:{"end":_vm.fetchLayouts}}):_vm._e(),(_vm.layoutItems.length)?_c('i',{staticClass:"mdi mdi-chevron-down",on:{"click":function($event){return _vm.toggleMenu($event)}}}):_vm._e(),_c('OverlayPanel',{ref:"menu",attrs:{"appendTo":"body"}},[_c('div',{staticClass:"p-d-flex p-flex-column"},_vm._l((_vm.layoutItems),function({ label, command, id }){return _c('Button',{key:id,staticClass:"p-button-blurred p-mb-1",attrs:{"label":label},on:{"click":function($event){return command(id)}}})}),1)])],1),(
            _vm.complexObject.childrens &&
            _vm.complexObject.childrens.length &&
            Object.keys(_vm.layers).length > 1
          )?_c('CreateLayoutButton',{attrs:{"ids":_vm.collectChildrensNodes(_vm.currentOpenedComplexObjectLayer),"message":"message.createLayerLayout","title":_vm.currentLayer,"tooltip":_vm.te('button.createLayerLayout'),"isLayer":""},on:{"end":_vm.fetchLayouts}}):_vm._e()],1),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"complex-object-panel__header p-mr-3"},[(_vm.currentRoom !== null)?_c('MarkerCircle',{attrs:{"color":_vm.currentRoom.iconColor || '#005AD1',"haveLeg":false,"id":_vm.currentRoom.id}},[_c('div',{staticClass:"marker-circle-content"},[_c('i',{staticClass:"mdi mdi-24px",class:_vm.currentRoom.iconClass,staticStyle:{"color":"white"}})])]):_vm._e()],1),(_vm.isComplexObjectSelected)?_c('div',{staticClass:"complex-object-panel"},[_c('div',{staticClass:"complex-object-panel__container"},[_c('div',{staticClass:"complex-object-panel__menu p-raised p-mb-3"},[_c('NestedMenu',{attrs:{"clickedOn":_vm.clickedOn,"nodeOptions":_vm.roomsOptions},on:{"update:clickedOn":function($event){_vm.clickedOn=$event},"update:clicked-on":function($event){_vm.clickedOn=$event},"end":_vm.fetchLayouts}})],1),(_vm.currentEntity !== null)?_c('div',{staticClass:"complex-object-panel__card"},[(_vm.currentEntity.type === 'Camera')?_c('CameraCard',{attrs:{"camera":_vm.currentEntity,"options":{
                isCompactView: true,
                toast: _vm.$toast,
                isMapView: true
              }}}):_vm._e(),(_vm.currentEntity.type === 'BolidDevice')?_c('BolidDeviceCard',{attrs:{"bolidDevice":_vm.currentEntity,"options":{
                isCompactView: true,
                isMapView: true,
                toast: _vm.$toast
              }}}):_vm._e()],1):_vm._e()])]):_vm._e()],1),(_vm.polygonData && _vm.polygonData.layersOrder.length > 1)?_c('div',{staticClass:"layers-switch"},[_c('div',{staticClass:"layers-switch__button-set p-raised"},_vm._l((_vm.polygonData.layersOrder),function(layer,index){return _c('Button',{key:layer,class:[layer === _vm.currentLayer ? '' : 'p-button-outlined p-button-secondary'],attrs:{"label":layer},on:{"click":function($event){_vm.currentLayerIndex = index}}})}),1)]):_vm._e()]):_vm._e(),(_vm.complexObjectMarkerType && _vm.complexObjectMarkerType.includes('tooltip'))?_c('div',{ref:`tooltip-${_vm._uid}`,staticClass:"folowingtooltip p-p-1",style:({ opacity: _vm.showTooltip && !_vm.isOpened ? 1 : 0 })},[_c('span',{domProps:{"textContent":_vm._s(_vm.breakIntoPieces(_vm.complexObject.title, _vm.maxLabelLength))}})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }