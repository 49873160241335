
import Vue from 'vue'

import { debounce, unbreakable } from '@/utils'
// @ts-ignore
import { ui, Marker, Coordinate, animation, LineString } from 'maptalks'
// @ts-ignore
import { ClusterLayer } from 'maptalks.markercluster'
import { iconsMap } from '@/iconsMap'
import { getWidgetMap } from '@/mapStore'
import { mapMutationsTyped, mapStateTyped } from '@/store'
import { te } from '@/translations'

const TOOLTIP_SYMBOL = {
  markerType: 'path',
  textFaceName: 'Geometria',
  markerWidth: 200,
  markerHeight: 50,
  markerDx: -135,
  markerDy: -25,
  markerLineWidth: 0,
  lineJoin: 'round',
  textFill: 'white',
  textSize: 14,
  texyAlign: 'center',
  textHorizontalAlignment: 'right',
  textVerticalAlignment: 'middle',
  textWrapWidth: 185,
  textDx: -225,
  textDy: -50,
  markerPathWidth: 200,
  markerPathHeight: 50,
  markerPath: {
    path: 'M9.5 0.5h180s10 0 10 10v30s0 10 -10 10h-180s-10 0 -10 -10v-30s0 -10 10 -10',
    fill: '#3c72ff',
    stroke: '#3c72ff'
  }
}

const TIP_TOOLTIP_SYMBOL = {
  markerType: 'path',
  textFaceName: 'Geometria',
  markerWidth: 200,
  markerHeight: 50,
  markerDx: 115,
  markerDy: 25,
  markerLineWidth: 0,
  lineJoin: 'round',
  textFill: 'white',
  textSize: 14,
  texyAlign: 'center',
  textHorizontalAlignment: 'right',
  textVerticalAlignment: 'middle',
  textWrapWidth: 185,
  textDx: 25,
  textDy: 0,
  markerPathWidth: 200,
  markerPathHeight: 50,
  markerPath: {
    path: 'M9.5 0.5h180s10 0 10 10v30s0 10 -10 10h-180s-10 0 -10 -10v-30s0 -10 10 -10',
    fill: '#3c72ff',
    stroke: '#3c72ff'
  }
}
let isClickedAndHeld = true as boolean | null
let isClickedOnMarker = false
export default Vue.extend({
  name: 'TMarker',
  props: {
    location: [Array, Object],
    id: String,
    clusterName: String,
    type: {
      type: String,
      required: false,
      default: 'circle'
    },
    icon: String,
    tallLeg: {
      type: Boolean,
      required: false,
      default: false
    },
    color: String,
    isVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: String,
    tipColor: String,
    tipTooltip: String,
    centerizeOnClick: Boolean,
    transparent: Boolean,
    zoomOnClick: Boolean,
    zIndex: Number,
    animationDuration: Number,
    title: String,
    cursor: String,
    isPolygonDataLoaded: Boolean
  },
  data: () => ({
    showPopup: false,
    animationDurationLocal: 0,
    wasClustered: false,
    markerPopUp: null as ui['InfoWindow'] | null,
    isClickedAndHeld: false,
    isClickedOnMarker: false
  }),
  created() {
    this.static = {
      parentMap: {} as any,
      marker: {} as any,
      proj: {} as any,
      markerGeometry: {} as any,
      markerCluster: {} as any,
      lastLocation: {} as any,
      line: {} as any,
      layer: {} as any,
      clusterLayer: {} as any,
      pointerMarker: {} as any,
      tipMarker: {} as any
    }
  },
  computed: {
    ...mapStateTyped([
      'clusters',
      'maxClusterRadius',
      'clusteredMarkersIds',
      'widgetUUID',
      'isDarkTheme',
      'clustersLocationMap',
      'clustersLocationMapCount',
      'visibleMarkersSet',
      'visibleMarkersSetTracker',
      'highlighted',
      'popupMode',
      'openedPopupsIds'
    ]),
    isPopupOpened(): boolean {
      return this.openedPopupsIds.includes(this.id)
    },
    isMarkerInViewport(): boolean {
      return this.id && !this.isPopupOpened
        ? !!this.visibleMarkersSetTracker && this.visibleMarkersSet.has(this.id)
        : true
    }
  },
  watch: {
    isActive() {
      this.static.marker.updateSymbol(this.makeMarker().symbol)
    },
    isDarkTheme() {
      this.static.marker.updateSymbol(this.makeMarker().symbol)
    },
    isPopupOpened(val: boolean, prev: boolean) {
      if (!this.$slots.popup) return
      if (!prev && val) {
        this.$emit('showPopup', true)
        this.static.clusterLayer.removeGeometry(this.static.markerCluster)
        this.static.marker.setOptions({
          cursor: 'grab'
        })
        this.static.marker.on('mouseDown', this.mouseDown)
        this.static.marker.on('mouseUp', this.mouseUp)

        this.static.tooltipMarker?.updateSymbol?.({
          textName: te('message.grabToDragg')
        })
        this.static.marker.config('draggable', true)
        this.static.line = new LineString([this.location, this.location], {
          arrowStyle: 'classic',
          arrowPlacement: 'vertex-first',
          symbol: {
            opacity: 0.7,
            lineColor: this.color || '#3c72ff',
            lineWidth: 1,
            colorStops: [
              [1, this.color || '#3c72ff'],
              [0, 'rgba(0,0,0,0)']
            ]
          }
        }).addTo(this.static.layer)
        setTimeout(() => {
          this.markerPopUp = new ui.InfoWindow({
            single: false,
            autoOpenOn: 'click',
            animation: 'fade',
            custom: false,
            content: this.$refs.popup
          })
          this.markerPopUp.addTo(this.static.marker)
          this.static.marker.openInfoWindow()
        })
      }

      if (prev && !val) {
        this.static.marker.closeInfoWindow()
        this.markerPopUp.remove()
        this.$emit('showPopup', false)
        this.static.marker.setOptions({
          cursor: 'pointer'
        })
        this.static.marker.config('draggable', false)
        this.static.marker.off('mouseDown', this.mouseDown)
        this.static.marker.off('mouseUp', this.mouseUp)
        this.static.marker.setCoordinates(this.location)
        this.static.pointerMarker.setCoordinates(this.location)
        this.static.layer.removeGeometry(this.static.line)
        this.isVisible && this.static.clusterLayer.addGeometry(this.static.markerCluster)
        this.static.tooltipMarker?.updateSymbol?.({
          textName: unbreakable(this.tooltip, 44)
        })
        this.static.tooltipMarker?.setCoordinates(this.location)
      }
    },
    isMarkerInViewport(val) {
      if (val) {
        this.isVisible && !this.wasClustered && this.show()
      } else {
        this.hide()
      }
    },
    isVisible(val) {
      if (val) {
        this.isMarkerInViewport && !this.wasClustered && this.show()
        if (this.clusterName) {
          this.static.clusterLayer = this.static.parentMap.getLayer(this.clusterName)
          this.static.clusterLayer.addGeometry(this.static.markerCluster)
        }
      } else {
        this.hide()
        if (this.clusterName) {
          this.static.clusterLayer.removeGeometry(this.static.markerCluster)
        }
      }
    }
  },
  methods: {
    ...mapMutationsTyped(['togglePopup']),
    mouseDown() {
      isClickedOnMarker = true
      isClickedAndHeld = false
      setTimeout(() => {
        isClickedAndHeld = null
      }, 200)
    },
    mouseUp() {
      if (!isClickedAndHeld && isClickedOnMarker) {
        this.click()
      }
      isClickedOnMarker = false
    },
    mouseenter() {
      this.static.tooltipMarker.show()
    },
    mouseout() {
      this.static.tooltipMarker.hide()
    },
    show() {
      this.static.marker?.show()
      this.static.pointerMarker?.show()
      this.static?.tipMarker?.show?.()
    },
    hide() {
      this.static.pointerMarker?.hide()
      this.static.marker?.hide()
      this.static?.tipMarker?.hide?.()
      this.isPopupOpened && this.togglePopup(this.id)
    },
    clusteredMarkersWatcher(val: string[]) {
      if (val.includes(this.id)) {
        this.static.lastLocation =
          this.clustersLocationMapCount && this.clustersLocationMap.get(this.id)
        !this.wasClustered &&
          (this.wasClustered = true) &&
          this.moveLocation(this.location, this.static.lastLocation, true)
      } else {
        this.isMarkerInViewport && this.isVisible && this.show()
        this.wasClustered &&
          !(this.wasClustered = false) &&
          this.moveLocation(this.static.lastLocation, this.location)
      }
    },
    moveLocation(from: any, to: any, hide = false) {
      try {
        const start = new Coordinate(from)
        const stop = new Coordinate(to)
        const offset = stop.sub(start)
        if (offset.x !== 0 || offset.y !== 0) {
          const player = animation.Animation.animate(
            {},
            {
              duration: 200,
              easing: 'out'
            },
            (frame: any) => {
              if (frame.state.playState === 'running') {
                this.static.marker.setCoordinates(
                  start.add({
                    x: offset.x * frame.state.delta,
                    y: offset.y * frame.state.delta
                  })
                )
              }
              if (frame.state.playState === 'finished') {
                this.static.marker.setCoordinates(stop)
                hide && this.hide()
              }
            }
          )
          player.play()
        }
      } catch (error) {
        this.static.marker.setCoordinates(new Coordinate(this.location))
      }
    },
    initClusters(vectorLayer: any) {
      const clusters = { ...this.clusters }
      const clustersList = vectorLayer.getClusters()
      clustersList.forEach((cluster: Cluster) => {
        cluster.location = this.static.proj.unproject(cluster.center)
        cluster.id = `${this.clusterName}_${cluster.location.x}${cluster.location.y}`
        cluster.parent = {}
        cluster.children = cluster.children.map(({ id, color }) => {
          this.clustersLocationMap.set(id, cluster.location)
          if (this.highlighted && id in this.highlighted) {
            const { color } = this.highlighted[id]
            color && (cluster.color = color)
          }
          return { id, color }
        })
      })
      clusters[`${this.clusterName}`] = clustersList
      this.$store.commit('setValue', ['clusters', { ...clusters }])
      this.$store.commit('setValue', ['clustersLocationMap', this.clustersLocationMap])
      this.$store.commit('setValue', [
        'clustersLocationMapCount',
        this.clustersLocationMapCount + 1
      ])
    },
    click() {
      this.$emit('click')
      this.$slots.popup && this.togglePopup(this.id)
      if (!this.isPopupOpened && this.$slots.popup) return
      this.centerizeOnClick && this.static.parentMap.panTo(this.location)
      if (this.zoomOnClick) {
        this.static.parentMap.animateTo({
          center: this.location,
          zoom: this.static.parentMap.getZoom() + 2
        })
      }
    },
    makeMarker() {
      return {
        visible: this.isVisible,
        zIndex: 1,
        dragShadow: false,
        symbol: [
          ...(this.type === 'circle'
            ? [
                {
                  markerType: 'ellipse',
                  markerFill: this.color || '#3c72ff',
                  markerLineColor: this.color || '#3c72ff',
                  markerLineWidth: 1,
                  markerFillOpacity: this.transparent ? 0.1 : this.isDarkTheme ? 0.4 : 1,
                  markerLineOpacity: this.transparent ? 0.1 : 1,
                  markerWidth: 48,
                  markerHeight: 48,
                  markerDy: this.tallLeg ? -100 : -54
                },
                {
                  markerType: 'square',
                  markerWidth: 2,
                  markerHeight: this.tallLeg ? 100 : 34,
                  markerFill: {
                    type: 'linear',
                    places: [1, 1, 0, 0],
                    colorStops: [
                      [1, this.color || '#3c72ff'],
                      [0, this.color || '#3c72ff'],
                      [0, 'rgba(0,0,0,0)']
                    ]
                  },
                  markerFillOpacity: this.transparent ? 0.1 : 1,
                  markerLineWidth: 0,
                  markerDy: this.tallLeg ? -27 : -13
                },
                ...(!this.tallLeg
                  ? [
                      {
                        markerType: 'ellipse',
                        markerFill: this.tipColor || this.color || '#3c72ff',
                        markerLineWidth: 0,
                        markerFillOpacity: this.transparent ? 0.1 : 0.2,
                        markerWidth: 10,
                        markerHeight: 10,
                        markerOpacity: this.transparent ? 0.1 : 1
                      },
                      {
                        markerType: 'ellipse',
                        markerFill: this.tipColor || this.color || '#3c72ff',
                        markerLineWidth: 0,
                        markerFillOpacity: this.transparent ? 0.1 : 0.8,
                        markerWidth: 3,
                        markerHeight: 3,
                        markerOpacity: this.transparent ? 0.1 : 1
                      }
                    ]
                  : [])
              ]
            : []),
          ...(this.type === 'camera'
            ? [
                {
                  markerType: 'ellipse',
                  markerFill: this.color || '#3c72ff',
                  markerLineWidth: 2,
                  markerLineColor: this.isActive ? 'white' : 'rgba(149, 157, 168, 1)',
                  markerFillOpacity: this.transparent ? 0.1 : 1,
                  markerOpacity: this.transparent ? 0.1 : 1,
                  markerWidth: 20,
                  markerHeight: 20,
                  textFaceName: 'Material Design Icons',
                  textFill: this.isActive ? 'white' : 'rgba(149, 157, 168, 1)',
                  textSize: 12,
                  textName: iconsMap?.[this.icon?.replace('mdi-', '')] || '󰕧',
                  textOpacity: this.transparent ? 0.1 : 1,
                  textDy: -1
                }
              ]
            : []),
          ...(this.icon
            ? [
                {
                  textFaceName: 'Material Design Icons',
                  textFill: this.transparent ? ' rgba(255, 255, 255, 0.1)' : 'white',
                  textSize: this.type === 'camera' ? 16 : 24,
                  textName: iconsMap?.[this.icon?.replace('mdi-', '')] || '󰕧',
                  textDy: this.type === 'camera' ? 3 : this.tallLeg ? -100 : -54
                }
              ]
            : []),
          ...(this.title
            ? [
                {
                  textFaceName: 'Geometria',
                  textSize: 16,
                  textName: this.title,
                  textDy: this.type === 'circle' ? -54 : 0,
                  textFill: this.transparent ? ' rgba(255, 255, 255, 0.1)' : 'white'
                }
              ]
            : [])
        ]
      }
    },
    makePointerMarker() {
      return {
        visible: this.isVisible,
        cursor: this.cursor || 'pointer',
        symbol: [
          ...(this.type === 'circle'
            ? [
                {
                  markerType: 'ellipse',
                  opacity: 0.01,
                  markerWidth: 48,
                  markerHeight: 48,
                  markerDy: this.tallLeg ? -100 : -54
                }
              ]
            : []),
          ...(this.type === 'camera'
            ? [
                {
                  markerType: 'ellipse',
                  markerWidth: 20,
                  markerHeight: 20,
                  opacity: 0.01
                }
              ]
            : []),
          ...(this.title && this.cursor
            ? [
                {
                  textFaceName: 'Geometria',
                  textSize: 16,
                  textName: this.title,
                  textDy: this.type === 'circle' ? -54 : 0,
                  textFill: ' rgba(255, 255, 255, 0.01)'
                }
              ]
            : [])
        ]
      }
    }
  },
  mounted() {
    this.animationDurationLocal = this.animationDuration ? this.animationDuration : 0
    this.static.parentMap = getWidgetMap(this.widgetUUID)
    this.static.proj = this.static.parentMap.getProjection()
    this.static.marker = new Marker(this.location, this.makeMarker())
    this.static.pointerMarker = new Marker(this.location, this.makePointerMarker())
    if (this.clusterName !== undefined) {
      this.static.markerCluster = new Marker(this.location, {
        symbol: {
          markerType: 'ellipse',
          markerFillOpacity: 0,
          markerLineWidth: 0
        }
      })
      // color need to identifying cluster marker color
      this.static.markerCluster.color = this.color || '#3c72ff'
      this.static.markerCluster.id = this.id
      this.static.clusterLayer = this.static.parentMap.getLayer(this.clusterName)
      if (this.static.clusterLayer === null) {
        this.static.clusterLayer = new ClusterLayer(this.clusterName, {
          zIndex: 20,
          maxClusterZoom: 17,
          maxClusterRadius: this.maxClusterRadius,
          drawClusterText: false,
          forceRenderOnZooming: false,
          symbol: {
            markerType: 'ellipse',
            markerFillOpacity: 0,
            markerLineWidth: 0
          }
        }).addTo(this.static.parentMap)
        this.static.parentMap.on('viewchange', () => {
          this.initClusters(this.static.clusterLayer)
        })
        this.static.clusterLayer.addMarker(
          new Marker([0, 0], {
            symbol: {
              markerType: 'ellipse',
              markerFillOpacity: 0,
              markerLineWidth: 0
            }
          })
        )
      }
      this.isVisible && this.static.clusterLayer.addGeometry(this.static.markerCluster)
      this.isVisible &&
        debounce(() => this.initClusters(this.static.clusterLayer), 300, this.clusterName)
      this.$watch('clusteredMarkersIds', this.clusteredMarkersWatcher)
    }
    this.static.layer = this.static.parentMap.getLayer('markersLayer')
    !this.isMarkerInViewport && this.hide()
    this.static.marker.addTo(this.static.layer)
    this.static.marker.on('dragging', (event: any) => {
      this.static.pointerMarker?.setCoordinates(event.coordinate)
      this.static.marker?.setCoordinates(event.coordinate)
      this.static.line?.setCoordinates([this.location, event.coordinate])
      this.static.tooltipMarker?.setCoordinates(event.coordinate)
    })
    this.static.pointerMarker.addTo(this.static.layer)
    this.static.pointerMarker.on('mouseDown', this.mouseDown)
    this.static.pointerMarker.on('mouseUp', this.mouseUp)
    this.static.pointerMarker.on('click', ({ domEvent }: { domEvent: MouseEvent }) =>
      domEvent.stopPropagation()
    )
    this.static.marker.on('click', ({ domEvent }: { domEvent: MouseEvent }) =>
      domEvent.stopPropagation()
    )
    const tooltipsLayer = this.static.parentMap.getLayer('tooltipsLayer')
    if (this.tooltip) {
      this.static.tooltipMarker = new Marker(this.location, {
        visible: false,
        symbol: { ...TOOLTIP_SYMBOL, textName: unbreakable(this.tooltip, 44) }
      })
      this.static.tooltipMarker.addTo(tooltipsLayer)
      this.static.pointerMarker.on('mouseenter', this.mouseenter)
      this.static.pointerMarker.on('mouseout', this.mouseout)
    }

    if (this.tipTooltip) {
      this.static.tipMarker = new Marker(this.location, {
        cursor: 'pointer',
        symbol: [
          {
            opacity: 0.01,
            markerType: 'ellipse',
            markerWidth: 10,
            markerHeight: 10
          }
        ]
      })
      this.static.tipMarker.addTo(this.static.layer)
      this.static.tipTooltipMarker = new Marker(this.location, {
        visible: false,
        symbol: { ...TIP_TOOLTIP_SYMBOL, textName: unbreakable(this.tipTooltip, 44) }
      })
      this.static.tipTooltipMarker.addTo(tooltipsLayer)
      this.static.tipMarker.on('mouseenter', () => this.static.tipTooltipMarker?.show())
      this.static.tipMarker.on('mouseout', () => this.static.tipTooltipMarker?.hide())
    }
  },
  destroyed() {
    this.hide()
    this.markerPopUp = null
    if (this.clusterName !== undefined) {
      this.static.markerCluster.remove()
    }
    setTimeout(() => {
      this.static.tooltipMarker?.remove()
      this.static.marker?.remove()
      this.static.pointerMarker?.remove()
      this.static.tipMarker?.remove?.()
      this.static.tipTooltipMarker?.remove?.()
    }, this.animationDurationLocal)
  }
})
