import {
  LIST_DELIMITER,
  STRING_QUOTE,
  BINARY_OPERATORS,
  UNARY_OPERATORS,
  STATEMENT_DELIMITER
} from './constants'
import { removeQuotes, splitWith, isString } from './utils'
import { Statements, Operand, ValueOf, BinaryOp } from './types'

const parseEnum = (v: string): Operand | undefined => {
  if (v === '') return
  const res = splitWith(v, LIST_DELIMITER, STRING_QUOTE).map((v) => {
    if (v === 'null') {
      return null
    } else if (v === 'true') {
      return true
    } else if (v === 'false') {
      return false
    } else {
      const num = Number(v)
      if (!isNaN(num)) {
        return num
      } else {
        return removeQuotes(v)
      }
    }
  })
  if (res.length === 1 && typeof res[0] === 'string' && res[0].includes('..')) {
    return res[0]
  }
  return res
}
const parseComparison = (v: string): number | string => {
  const num = Number(v)
  return isNaN(num) ? removeQuotes(v) : num
}
const parseMatch = (v: string): string | undefined => {
  return v.length > 0 ? v : undefined
}

const valueParsers: Record<BinaryOp, (s: string) => Operand | undefined> = {
  '~=': parseMatch,
  '==': parseEnum,
  ':': parseEnum,
  '!=': parseEnum,
  '>': parseComparison,
  '>=': parseComparison,
  '<': parseComparison,
  '<=': parseComparison
}

const parseStatement = (s: string): [string, ValueOf<Statements>] | undefined => {
  // try every binary operator and pick corresponding parser for value
  for (const operator of BINARY_OPERATORS) {
    const splits = s.split(operator)
    if (splits.length === 2) {
      const [attr, raw] = splits
      const parser = valueParsers[operator]
      const value = parser(raw)
      if (value === undefined) {
        return
      }
      return [
        attr,
        {
          [operator]: value
        }
      ]
    }
  }
  // try every unary operator
  for (const operator of UNARY_OPERATORS) {
    if (s.startsWith(operator)) {
      return [s.slice(operator.length), { [operator]: null }]
    }
  }
  return undefined
}

export const parseQ = (query: string): Statements => {
  if (isString(query) && query.length > 0) {
    const statements = {} as Statements
    const statementsList = splitWith(query, STATEMENT_DELIMITER, STRING_QUOTE).map(parseStatement)
    for (const statement of statementsList) {
      if (statement) {
        const [attribute, value] = statement
        statements[attribute] = { ...(statements[attribute] || {}), ...value }
      }
    }
    return statements
  } else {
    return {}
  }
}
