
import { mapStateTyped } from '@/store'
import { debounce } from '@/utils'
import Vue from 'vue'
export default Vue.extend({
  name: 'CameraCard',
  props: {
    camera: Object as () => Camera,
    options: Object
  },
  data() {
    return {
      unmount: () => {}
    }
  },
  computed: {
    ...mapStateTyped([
      'spaParent',
      'assignmentTypes',
      'permissionScopes',
      'analytics',
      'assignmentGroups',
      'spaProps'
    ]),
    updateWatch(): unknown[] {
      return [this.camera, this.permissionScopes]
    }
  },
  watch: {
    updateWatch() {
      debounce(
        () => {
          this.unmount()
          setTimeout(() => {
            // @ts-ignore
            this.unmount = this.mountCard()
          })
        },
        500,
        this.camera.id
      )
    }
  },
  methods: {
    mountCard() {
      try {
        let ref = this.$refs[`card-${this.camera.id}`]
        ref = Array.isArray(ref) ? ref[0] : ref
        const camCard = this.spaParent?.areas.find((e: any) => e.name === 'Camera')
        if (camCard !== undefined && ref !== undefined) {
          const assignedAnalytics = this.analytics.filter(
            (el: any) => el.entityId === this.camera.id
          )
          return this.spaParent?.mountChildren(
            ref,
            camCard.children.map((e: any) => {
              return {
                ...e,
                props: {
                  ...e.props,
                  camera: this.camera,
                  assignedAnalytics,
                  options: {
                    showArchivePlayerContainer: (val: Boolean) => {
                      this.$store.commit('setValue', ['showArchivePlayerContainer', val])
                    },
                    showAssignmentDialogContainer: (val: Boolean) => {
                      this.$store.commit('setValue', ['showAssignmentDialogContainer', val])
                    },
                    permissionScopes: this.permissionScopes,
                    assignmentTypes: this.assignmentTypes,
                    analytics: this.analytics,
                    assignmentGroups: this.assignmentGroups || [],
                    ...this.options
                  }
                }
              }
            })
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  mounted() {
    this.unmount = this.mountCard() || (() => {})
  },
  beforeDestroy() {
    this.unmount()
  }
})
