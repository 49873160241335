
import { history, SearchParams } from '@netvision/lib-history'
import Menu from 'primevue/menu'
import Mixins from './common/Mixins'
/* eslint-disable no-unused-vars */
import { FeatureCollection } from 'geojson'
import ComplexObjectCard from './common/ComplexObjectCard.vue'
import TGeoJSON from './common/TGeoJSON.vue'
// @ts-ignore
import { GeoJSON } from 'maptalks'
import TMarker from './common/TMarker.vue'
import TPolygon from './common/TPolygon.vue'
import TSector from './common/TSector.vue'
import TCircle from './common/TCircle.vue'
import NestedMenu from './common/NestedMenu.vue'
import { mapMutationsTyped, mapStateTyped } from '@/store'
import { getWidgetMap } from '@/mapStore'
import { uuid, toNumberOrNaN } from '@/utils'
import CreateLayoutButton from './common/CreateLayoutButton.vue'
import { listEntities } from '@netvision/lib-api-gateway'
type ConstructionsLabels = {
  id: Uuid
  label: string
  position: [number, number]
}[]

interface FeatureCollectionEntity extends FeatureCollection {
  id: string
}
type MenuItem = { id: string; label: string; command(id: string): void }

export default Mixins.extend({
  name: 'ComplexObjectOnMap',
  components: {
    Menu,
    ComplexObjectCard,
    TGeoJSON,
    TMarker,
    TPolygon,
    TCircle,
    TSector,
    NestedMenu,
    CreateLayoutButton
  },
  props: {
    complexObject: Object as () => ComplexObject & CompositionEntity,
    isRoot: {
      type: Boolean,
      default: false
    },
    isParentFiltered: {
      type: Boolean,
      default: false
    },
    parentExpandingZoom: {
      type: Number,
      default: undefined
    },
    activeFilters: Object
  },
  data() {
    return {
      loading: false,
      isClickedAndHeld: false,
      cameraPositionBeforeOpen: undefined as any,
      openPosition: null as any,
      maxChars: 25,
      lastOpenedComplexObjectId: undefined as any,
      clickedOn: [] as string[],
      currentRoutePath: history.location.pathname,
      unlisten: () => {},
      showTooltip: false,
      tooltip: null as null | HTMLElement,
      complexObjectMarkerType: [] as SPAProps['complexObjectMarkerType'],
      layouts: [] as IEntity[],
      thresholdZoomLevel: 15,
      currentLayerIndex: 0,
      polygonData: null as Pick<BaseComplexObject, 'outline' | 'layersOrder'> | null
    }
  },
  created() {
    this.static = {
      parentMap: {} as any
    }
    this.$eventBus.$on('openCoplexObjectById', this.openIfMyId)
  },
  asyncComputed: {
    async rooms(): Promise<NodeObject[]> {
      if (this.currentOpenedComplexObjectLayer?.length > 0) {
        const filteredCOAreaLayers = this.currentOpenedComplexObjectLayer.reduce<NodeObject[]>(
          (acc, next) => {
            if (this.areaEntityTypes.includes(next.type)) {
              acc.push(next as NodeObject)
            }
            return acc
          },
          []
        )

        return await Promise.all(
          filteredCOAreaLayers.map(async (el) => {
            return { ...el, ...(await this.getLayerProps(el.id, el.type)) }
          })
        )
      }
      return []
    }
  },
  computed: {
    ...mapStateTyped([
      'api',
      'widgetUUID',
      'permissionScopes',
      'clusteredEntitiesIds',
      'filteredEntitiesTracker',
      'filteredEntitiesIds',
      'currentEntityId',
      'selectedMode',
      'minZoom',
      'clusteringByType',
      'spaProps',
      'currentZoom',
      'prevRoutes',
      'highlightedIds',
      'highlighted',
      'fullWidth',
      'fullHeight',
      'maxLabelLength',
      'areaEntityTypes',
      'visibleMarkersSet',
      'visibleMarkersSetTracker',
      'currentOpenedComplexObject'
    ]),
    isTransparent(): boolean {
      return (
        this.highlightedIds.length !== 0 &&
        (!this.highlighted[this.complexObject.id] ||
          !this.filteredEntitiesIds.has(this.complexObject.id))
      )
    },
    isFiltered(): boolean {
      return (
        this.filteredEntitiesTracker &&
        (this.filteredEntitiesIds.has(this.complexObject.id) || this.isParentFiltered)
      )
    },
    layoutItems(): MenuItem[] {
      return this.layouts.map(({ name, id, layoutType }) => ({
        id,
        label: `${name} ${layoutType || ''}` as string,
        command(id) {
          setTimeout(() => {
            window.open(`/layouts?currentCamerasLayoutId="${id}"`)
          })
        }
      }))
    },
    currentLayer(): string {
      return this.polygonData?.layersOrder
        ? this.polygonData?.layersOrder[this.currentLayerIndex]
        : '1'
    },
    expandingZoom(): number {
      return (
        toNumberOrNaN(this.parentExpandingZoom) ||
        toNumberOrNaN(this.complexObject.expandingZoom) ||
        16
      )
    },
    overlayStyle(): Record<string, string> {
      let widthOffset = 354
      const heightOffset = this.fullHeight - 600 < 0 ? 24 : 126
      switch (true) {
        case this.fullWidth - 1480 < 0:
          widthOffset = 54
          break
        case this.fullWidth - 1540 < 0:
          widthOffset = 124
          break
      }
      return {
        top: `calc(${heightOffset}rem / var(--bfs))`,
        width: `calc(100% - ${widthOffset}px)`,
        'max-height': `calc(100% - ${heightOffset + 30}px)`
      }
    },
    clusterName(): string | undefined {
      return this.clusteringByType
        ? `${this.isRoot ? '' : 'child'}complexObjects`
        : (this.isRoot && 'main') || undefined
    },
    isMarkerOfCollapsedCOVisible(): boolean {
      return (
        !this.cameraCloseEnough && !this.isComplexObjectSelected && this.isRoot && this.isFiltered
      )
    },
    isMarkerOfExpandedCOVisible(): boolean {
      return (
        this.cameraCloseEnough && !this.isComplexObjectSelected && !this.isOpened && this.isFiltered
      )
    },
    isLabelOfCOVisible(): boolean {
      return (
        this.isComplexObjectSelected &&
        !this.isOpened &&
        this.cameraCloseEnough &&
        this.currentOpenedComplexObject.id === this.complexObject.servicePath &&
        !this.isRoot
      )
    },
    isCOOutlineVisible(): Boolean {
      return (
        (!this.isComplexObjectSelected ||
          [this.complexObject.servicePath, this.complexObject.id].includes(
            this.currentOpenedComplexObject.id
          )) &&
        this.isFiltered
      )
    },
    layers(): Record<string, (IMapEntity | FeatureCollectionEntity)[]> {
      if (this.complexObject.childrens) {
        const layers = {} as Record<string, IMapEntity[]>
        this.complexObject.childrens.forEach((child) => {
          if (child.type === 'ComplexObject') return
          const layerName =
            child.parentLayerName || child.layerName || child.layer || this.currentLayer || 'base'
          layers[layerName] = [...(layers[layerName] || []), child]
        })
        return layers
      }
      return {}
    },
    currentOpenedComplexObjectLayer(): (IMapEntity | FeatureCollectionEntity)[] {
      if (Object.keys(this.layers).length > 0) {
        return this.layers[this.currentLayer]
      }
      return []
    },
    constructionsLabels(): ConstructionsLabels {
      const featureCollection = this.currentOpenedComplexObjectLayer?.filter(
        ({ type }) => type === 'FeatureCollection'
      ) as FeatureCollection[]
      const labels = [] as ConstructionsLabels
      featureCollection?.forEach(({ features }) => {
        features.forEach((feature) => {
          if (feature.properties && feature.properties.label) {
            const { x, y } = GeoJSON.toGeometry(feature).getCenter()
            labels.push({
              id: uuid(),
              label: feature.properties.label as string,
              position: [x, y]
            })
          }
        })
      })
      return labels
    },
    cameraCloseEnough(): Boolean {
      return this.expandingZoom <= Math.ceil(this.currentZoom)
    },
    complexObjectBaseOption(): Record<string, any> {
      const symbol = [
        {
          lineColor: this.highlightedColor(this.complexObject),
          lineWidth: 6,
          polygonFill: this.highlightedColor(this.complexObject),
          polygonOpacity: 0.3,
          lineJoin: 'round',
          opacity:
            this.isMatchToSearchFilter(this.complexObject.title) &&
            (this.isFiltered || !this.isRoot)
              ? 1
              : 0.2
        }
      ] as any
      if (this.complexObjectMarkerType?.includes('label')) {
        symbol.push({
          textFaceName: 'Geometria',
          textName: this.complexObject.title,
          textFill: this.highlightedColor(this.complexObject),
          textWeight: '600',
          textWrapCharacter: ' ',
          textHorizontalAlignment: 'middle',
          textOpacity:
            this.isMatchToSearchFilter(this.complexObject.title) &&
            (this.isFiltered || !this.isRoot)
              ? 1
              : 0.2,
          textSize: {
            stops: [
              [7, 2],
              [16, 30]
            ]
          }
        })
      }
      return {
        symbol,
        zIndex: this.isRoot ? 2 : 3
      }
    },
    roomsOptions(): any {
      let roomOptions = [] as any
      if (this.rooms?.length > 0) {
        roomOptions = this.rooms.map((room) => {
          const option = {
            id: room.id,
            label: room.title,
            class: 'panel-menu',
            createLayoutButton: room.type === 'ComplexObjectArea'
          } as Record<string, any>
          if (room?.childrens?.length > 0) {
            option.items = room.childrens
              .filter((entity: IMapEntity) => ['BolidDevice', 'Camera'].includes(entity.type))
              .map((entity: IMapEntity) => {
                return {
                  id: entity.id,
                  label: entity.title,
                  icon: `mdi mdi-24px ${entity.iconClass}`,
                  class: 'panel-submenu',
                  command: () => {
                    this.clickedOn = [room.id, entity.id]
                    this.zoomToLocation(room)
                  }
                }
              })
          }
          return option
        })
      }
      if (this.innerComplexObjects.length > 0) {
        roomOptions = [
          ...roomOptions,
          ...this.innerComplexObjects.map((complexObject) => {
            return {
              id: complexObject.id,
              label: complexObject.title,
              icon: `mdi mdi-24px ${complexObject.iconClass}`,
              command: () => {
                this.$eventBus.$emit('openCoplexObjectById', complexObject.id)
              }
            }
          })
        ]
      }
      return roomOptions
    },
    currentFeatureCollections(): FeatureCollectionEntity[] {
      if (this.currentOpenedComplexObjectLayer?.length > 0) {
        return this.currentOpenedComplexObjectLayer.filter(
          ({ type }: any) => type === 'FeatureCollection'
        ) as FeatureCollectionEntity[]
      }
      return []
    },
    currentRoom(): NodeObject | null {
      const room = this.rooms?.find((room) => this.clickedOn.includes(room.id))
      return room || null
    },
    currentEntity(): Camera | BolidDevice | null {
      const entity =
        this.currentRoom !== null
          ? this.currentRoom?.childrens?.find((entity: IMapEntity) =>
              this.clickedOn.includes(entity.id)
            )
          : null
      return entity || null
    },
    isOpened(): Boolean {
      return this.isComplexObjectSelected
        ? this.currentOpenedComplexObject.id === this.complexObject.id
        : false
    },
    innerComplexObjects(): ComplexObject[] {
      //
      // '3ad531c3-641a-4652-a7e5-889f7c50dfb0'
      return (
        (this.complexObject?.childrens?.filter(
          ({ servicePath, type }: any) =>
            servicePath === this.complexObject.id && type === 'ComplexObject'
        ) as ComplexObject[]) || []
      )
    },
    defaultPosition(): ComplexObject['outline']['topViewSettings'] {
      const safeCenter =
        this.polygonData?.outline?.topViewSettings?.center ||
        (this.complexObject?.location
          ?.split(', ')
          ?.reverse()
          .map((e) => Number(e)) as [number, number])
      const { bearing, pitch, center, zoom } = this.polygonData?.outline?.topViewSettings || {
        zoom: 19,
        pitch: 0,
        bearing: 0,
        center: safeCenter
      }
      return {
        center: center || safeCenter,
        zoom: zoom ?? this.complexObject?.expandingZoom ?? 18,
        bearing: bearing ?? 0,
        pitch: pitch ?? 0
      }
    }
  },
  watch: {
    async visibleMarkersSetTracker() {
      if (
        !this.polygonData &&
        this.visibleMarkersSet.has(this.complexObject.id) &&
        this.static.parentMap.getZoom() >= this.thresholdZoomLevel
      ) {
        this.polygonData = await this.getLayerProps(this.complexObject.id)
      }
    },
    isOpened(val, oldVal) {
      if (val && !oldVal) {
        const complexObjectOverlay = document.getElementById(
          `complex-object-overlay-${this.complexObject.id}`
        )
        if (complexObjectOverlay) {
          document.addEventListener('mousedown', this.mouseDown)
          document.addEventListener('mouseup', this.mouseUp)
        }
      } else if (!val && oldVal) {
        document.removeEventListener('mousedown', this.mouseDown)
        document.removeEventListener('mouseup', this.mouseUp)
      }
    }
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
    async getLayerProps(id: string, type = 'ComplexObject') {
      try {
        if (!('getEntitiesWithGlobalBatch' in this.api)) {
          console.error(
            'method "getEntitiesWithGlobalBatch" is not implemented in current API repository'
          )
          return null
        }
        const { outline, expandingZoom, layersOrder }: any =
          await this.api.getEntitiesWithGlobalBatch(
            { id, type },
            ['outline', 'layersOrder'],
            'complex-object-map'
          )

        return { outline, expandingZoom, layersOrder } as Pick<
          BaseComplexObject,
          'outline' | 'layersOrder'
        >
      } catch (error) {
        console.error(error)
        return null
      }
    },
    toggleMenu(event: Event) {
      ;(this.$refs?.menu as Element & { toggle: (event: Event) => void }).toggle?.(event)
    },
    clickOnRoomLabel(room: ComplexObject | NodeObject) {
      const firstDevice =
        room.childrens && room.childrens.length > 0 ? room.childrens[0] : undefined
      this.clickedOn = firstDevice ? [room.id, firstDevice.id] : [room.id]
      this.isClickedAndHeld = true
      this.zoomToLocation(room)
    },
    setComplexObject(complexObject: ComplexObject) {
      this.setValue(['currentEntityId', complexObject.id])
    },
    setCamera(...arg: string[]) {
      setTimeout(() => (this.clickedOn = arg), 300)
    },
    collectChildrensNodes(
      childrens: CompositionEntity['childrens'] | (FeatureCollectionEntity | IMapEntity<string>)[]
    ) {
      let aggr = [] as string[]
      childrens?.forEach((child) => {
        if ('childrens' in child) {
          aggr = [
            ...aggr,
            ...(child.type === 'ComplexObjectArea' ? [child.id] : []),
            ...this.collectChildrensNodes(child.childrens as CompositionEntity['childrens'])
          ]
        }
      })
      return aggr
    },
    mouseDown() {
      this.isClickedAndHeld = false
      setTimeout(() => {
        this.isClickedAndHeld = true
      }, 200)
    },
    showTooltipWatcher(value: boolean) {
      if (value && !this.isOpened) {
        document.addEventListener('mousemove', this.folowMouse, false)
      } else if (!value) {
        document.removeEventListener('mousemove', this.folowMouse)
      }
    },
    highlightedColor(entity: Camera | ComplexObject | BolidDevice | NodeObject): string {
      if (!entity.id) return entity.iconColor || '#005AD1'
      return this.highlighted[entity.id]?.color || entity.iconColor || '#005AD1'
    },
    folowMouse(e: MouseEvent) {
      // @ts-ignore
      if (e.path && e.path[0] && e.path[0].nodeName === 'CANVAS') {
        if (this.tooltip && this.showTooltip) {
          this.tooltip.style.left = `${e.x}px`
          this.tooltip.style.top = `${e.y}px`
        }
      } else {
        this.showTooltip = false
      }
    },
    isMatchToSearchFilter(str: string) {
      if (this.highlightedIds.length !== 0) {
        return this.highlightedIds.includes(this.complexObject.id)
      }
      return typeof this.activeFilters.title === 'string' && typeof str === 'string'
        ? str.toLowerCase().includes(this.activeFilters.title.toLowerCase())
        : true
    },
    zoomToLocation(entity: ComplexObject | ComplexObjectArea) {
      if (entity.outline) {
        const { center, zoom } = entity.outline?.topViewSettings || {}
        this.static.parentMap.animateTo({
          center,
          zoom
        })
      }
    },
    mouseUp(event: MouseEvent) {
      const { target }: { target: (EventTarget & { nodeName?: string }) | null } = event
      if (
        target &&
        target.nodeName === 'CANVAS' &&
        !this.isClickedAndHeld &&
        this.currentOpenedComplexObject.id === this.complexObject.id
      ) {
        this.clickedOn = []
      }
    },
    openIfMyId(id: string) {
      if (id === this.complexObject.id) {
        this.openComplexObject()
      }
    },
    clickOnRoom(room: NodeObject) {
      const firstDevice =
        room.childrens && room.childrens.length > 0 ? room.childrens[0] : undefined
      this.clickedOn = firstDevice ? [room.id, firstDevice.id] : [room.id]
      this.zoomToLocation(room)
    },
    async openComplexObject(needToRoute: boolean = true) {
      if (!this.polygonData) {
        this.polygonData = await this.getLayerProps(this.complexObject.id)
      }
      this.fetchLayouts()
      this.$store.commit('setValue', ['currentOpenedComplexObject', this.complexObject])
      try {
        this.static.parentMap.animateTo(
          this.defaultPosition,
          {
            duration: 500
          },
          (frame: any) => {
            if (frame.state.playState === 'finished') {
              this.static.parentMap.setMinZoom((this.complexObject?.expandingZoom || 1) - 1)
            }
          }
        )
        this.static.parentMap.config('dragPitch', false)
        this.static.parentMap.config('touchPitch', false)
        if (needToRoute) {
          const query = SearchParams.parse(history.location.search)
          history.push({
            search: SearchParams.stringify({
              ...query,
              complexObject: this.complexObject.id
            })
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    returnToPreviousPosition() {
      this.$store.commit('setValue', ['currentOpenedComplexObject', {}])
      const cameraPositionBeforeOpen =
        this.cameraPositionBeforeOpen ||
        JSON.parse(
          localStorage.getItem(`cameraPositionBeforeOpen${history.location.pathname}`) || 'null'
        ) ||
        this.spaProps.camera
      this.static.parentMap.setMaxExtent(this.static.parentMap.getFullExtent())
      this.static.parentMap.setMinZoom(this.minZoom)
      this.static.parentMap.animateTo(cameraPositionBeforeOpen, {
        duration: 500
      })
      if (this.selectedMode === 'gl') {
        this.static.parentMap.config('dragPitch', true)
        this.static.parentMap.config('touchPitch', true)
      }

      localStorage.removeItem(`cameraPositionBeforeOpen${history.location.pathname}`)
      const query = { ...SearchParams.parse(history.location.search) }
      delete query.complexObject
      history.push({
        search: SearchParams.stringify(query)
      })
    },
    closeComplexObject() {
      let query = {} as any
      let path = ''
      if (this.prevRoutes.length > 0) {
        const prevRoute = this.prevRoutes.pop()
        query = prevRoute.query || {}
        path = prevRoute.path
        this.$store.commit('setValue', ['prevRoutes', this.prevRoutes])
      }
      if (!('complexObject' in query) || path !== this.currentRoutePath) {
        this.returnToPreviousPosition()
      } else {
        history.go(-1)
      }
    },
    getTopViewZoom(room: NodeObject) {
      return room.outline.topViewSettings?.zoom || 18
    },
    flyToDefault() {
      this.static.parentMap.animateTo(this.defaultPosition, {
        duration: 500
      })
    },
    fetchLayouts() {
      const ids = this.collectChildrensNodes(this.complexObject.childrens)
      ids?.length &&
        this.api
          .getEntitiesList<IEntity>({
            limiter: {
              type: 'CamerasLayout',
              keyValues: true,
              limit: 1000
            },
            filter: {
              q: [
                {
                  key: 'camerasParents',
                  operator: '==',
                  value: ids.join(',')
                }
              ]
            }
          })
          .then(({ results }) => (this.layouts = results))
          .catch(this.errorToast)
    }
  },
  async mounted() {
    this.static.parentMap = getWidgetMap(this.widgetUUID)
    this.unlisten = history.listen((location) => {
      const { complexObject } = SearchParams.parse(location.search)

      if (
        complexObject !== this.lastOpenedComplexObjectId &&
        complexObject === this.complexObject.id
      ) {
        const cameraPositionBeforeOpen = localStorage.getItem(
          `cameraPositionBeforeOpen${history.location.pathname}`
        )
        if (cameraPositionBeforeOpen === null) {
          this.cameraPositionBeforeOpen = this.static.parentMap.getView()
          localStorage.setItem(
            `cameraPositionBeforeOpen${history.location.pathname}`,
            JSON.stringify(this.cameraPositionBeforeOpen)
          )
        } else {
          this.cameraPositionBeforeOpen = JSON.parse(cameraPositionBeforeOpen)
        }
        this.openComplexObject(false)
      } else if (!complexObject && this.isOpened) {
        this.returnToPreviousPosition()
      }
      this.lastOpenedComplexObjectId = complexObject
    })
    this.complexObjectMarkerType = Array.isArray(this.spaProps.complexObjectMarkerType)
      ? this.spaProps.complexObjectMarkerType
      : ['tooltip', 'label']
    const { complexObject: complexObjectId } = SearchParams.parse(history.location.search)
    if (complexObjectId === this.complexObject.id) {
      this.openComplexObject(false)
      this.lastOpenedComplexObjectId = complexObjectId
    }

    if (this.complexObjectMarkerType?.includes('tooltip')) {
      this.$watch('showTooltip', this.showTooltipWatcher)
      this.tooltip = this.$refs[`tooltip-${this._uid}`] as HTMLElement
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('openCoplexObjectById', this.openIfMyId)
    this.unlisten()
  }
})
