
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'ComplexObjectCard',
  props: {
    complexObject: Object as () => ComplexObject,
    options: Object
  },
  data() {
    return {
      unmount: () => {}
    }
  },
  computed: {
    ...mapState(['spaParent'])
  },
  watch: {
    isCompactView() {
      this.unmount()
      this.mountCard()
    }
  },
  methods: {
    mountCard() {
      try {
        let ref = this.$refs[`card-${this.complexObject.id}`]
        ref = Array.isArray(ref) ? ref[0] : ref
        const objectCard = this.spaParent.areas.find((e: any) => e.name === 'ComplexObject')
        if (objectCard && ref) {
          return this.spaParent.mountChildren(
            ref,
            objectCard.children.map((e: any) => {
              return {
                ...e,
                props: {
                  ...e.props,
                  complexObject: this.complexObject,
                  options: this.options
                }
              }
            })
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  mounted() {
    this.unmount = this.mountCard()
  },
  beforeDestroy() {
    this.unmount()
  }
})
