import Vue from 'vue'
import { mapStateTyped } from '@/store'
import { locationToCoordinates, breakIntoPieces, unbreakable } from '@/utils'
import { te } from '@/translations'

export default Vue.extend({
  name: 'Mixins',
  data() {
    return {
      inputTimer: {} as any,
      flyComplete: true
    }
  },
  computed: {
    ...mapStateTyped([
      'spaParent',
      'areaEntityTypes',
      'leafEntityTypes',
      'isDarkTheme',
      'currentOpenedComplexObject',
      'complexObjectsCache',
      'maxLabelLength'
    ]),
    isComplexObjectSelected(): Boolean {
      return Object.keys(this.currentOpenedComplexObject).length !== 0
    }
  },
  methods: {
    te,
    errorToast(error: any) {
      console.error(error)
      this.$toast.add({
        severity: 'error',
        summary: this.te('error'),
        detail: error.message,
        life: 5000
      })
    },
    locationToCoordinates,
    breakIntoPieces,
    unbreakable
  }
})
