
import Vue from 'vue'
import {
  Map as Maptalks,
  Layer,
  VectorLayer,
  GroupTileLayer
  // @ts-ignore
} from 'maptalks'
import { history, SearchParams } from '@netvision/lib-history'
import { mapMutationsTyped, mapStateTyped } from '@/store'
import { debounce, coordinatesTolocation } from '@/utils'
import { setWidgetMap } from '@/mapStore'
import { layerFactory } from './layersFactory'

const defaultify = (str: string, isDark?: boolean) =>
  str === 'default' ? `default${isDark ? 'Dark' : 'Light'}` : str

export default Vue.extend({
  name: 'MapCanvas',
  props: {
    mapView: Object,
    center: Array,
    zoom: Number,
    pitch: Number,
    bearing: Number
  },
  data: () => ({
    isClickedAndHolded: true,
    currentRoutePath: '' as any,
    switcher: false,
    seamlessZoom: false
  }),
  computed: {
    ...mapStateTyped([
      'widgetUUID',
      'isDarkTheme',
      'selectedMode',
      'minZoom',
      'maxZoom',
      'markersRangeTree',
      'currentOpenedComplexObject',
      'visibleMarkersSetTracker',
      'spaProps',
      'currentLayerName',
      'filteredEntitiesTracker'
    ]),
    isComplexObjectSelected(): Boolean {
      return Object.keys(this.currentOpenedComplexObject).length !== 0
    }
  },
  watch: {
    filteredEntitiesTracker() {
      const { parentMap } = this.static
      if (!parentMap) return
      const zoom = parentMap.getZoom()
      parentMap.setZoom(zoom + (this.switcher ? 0.001 : -0.001))
      this.switcher = !this.switcher
    },
    selectedMode(val) {
      const { parentMap } = this.static
      if (val === 'canvas') {
        parentMap.setPitch(0)
        parentMap.config('dragPitch', false)
        parentMap.config('touchPitch', false)
      } else {
        parentMap.setPitch(45)
        parentMap.config('dragPitch', true)
        parentMap.config('touchPitch', true)
      }
    },
    isDarkTheme(val) {
      if (this.currentLayerName !== 'default') return
      if (val) {
        this.static.layersMap.defaultLight.hide()
        this.static.layersMap.defaultDark.show()
      } else {
        this.static.layersMap.defaultLight.show()
        this.static.layersMap.defaultDark.hide()
      }
    },
    currentLayerName(val, prevVal) {
      this.static.layersMap?.[defaultify(prevVal, this.isDarkTheme)]?.hide()
      this.static.layersMap?.[defaultify(val, this.isDarkTheme)]?.show()
    },
    currentOpenedComplexObject(val) {
      if (val) {
        this.static.popupLayer.hide()
      } else {
        this.static.popupLayer.show()
      }
    }
  },
  created() {
    this.static = {
      parentMap: {} as Maptalks,
      layersMap: {} as Record<string, Layer>,
      baseTileLayer: new GroupTileLayer('base', [])
    } as const
    window.addEventListener('keydown', this.plusMinusClickHandler)
  },
  methods: {
    ...mapMutationsTyped(['clickOnMap']),
    zoomIn() {
      this.static.parentMap.zoomIn()
    },
    zoomOut() {
      this.static.parentMap.zoomOut()
    },
    switchZoomMode(e: WheelEvent) {
      const isMouse = e.deltaY > 60 || e.deltaY < -60
      if (isMouse && this.seamlessZoom) {
        this.seamlessZoom = false
        this.static.parentMap.config({
          seamlessZoom: false,
          touchZoom: true
        })
      } else if (!isMouse && !this.seamlessZoom) {
        this.seamlessZoom = true
        this.static.parentMap.config({
          seamlessZoom: true,
          touchZoom: true
        })
        this.static.parentMap.scrollWheelZoom._delta = 0
      }
    },
    onZoom() {
      this.$store.commit('setValue', ['currentZoom', Math.round(this.static.parentMap.getZoom())])
    },
    plusMinusClickHandler(e: KeyboardEvent) {
      if (e.key === '+') {
        this.zoomIn()
      }
      if (e.key === '-') {
        this.zoomOut()
      }
    },
    updateVisibleMarkersSet(map: any) {
      const { xmax, xmin, ymax, ymin } = map.getExtent()
      if (this.markersRangeTree?.queryRange) {
        const dx = (xmax - xmin) * 0.05
        const dy = (ymax - ymin) * 0.05
        this.$store.commit('setValue', [
          'visibleMarkersSet',
          this.markersRangeTree.queryRange({
            xmax: xmax - 3 * dx,
            xmin: xmin + 3 * dx,
            ymax: ymax - dy,
            ymin: ymin + dy
          })
        ])
        this.$store.commit('setValue', [
          'visibleMarkersSetTracker',
          this.visibleMarkersSetTracker + 1
        ])
      }
    }
  },
  mounted() {
    const resolutions = []
    const d = 2 * 6378137 * Math.PI
    for (let i = 0; i < this.maxZoom + 1; i++) {
      resolutions[i] = d / (256 * Math.pow(2, i))
    }
    const { center, zoom, pitch, bearing } = this.mapView
    const contstr = `${this._uid}_map-container`
    this.static.parentMap = new Maptalks(contstr, {
      center,
      devicePixelRatio: 2,
      pitch: this.selectedMode === 'gl' ? (pitch === undefined ? 45 : pitch) : 0,
      zoom: !zoom ? 14 : zoom <= 20 ? zoom : 20,
      dragPitch: this.selectedMode === 'gl',
      touchPitch: this.selectedMode === 'gl',
      // bearing: bearing ?? 0,
      dragRotatePitch: false,
      dragRotate: false,
      touchRotate: false,
      maxPitch: 65,
      spatialReference: {
        projection: 'EPSG:3857',
        resolutions: resolutions,
        fullExtent: {
          top: 6378137 * Math.PI,
          left: -6378137 * Math.PI,
          bottom: -6378137 * Math.PI,
          right: 6378137 * Math.PI
        }
      },
      hitDetect: false,
      fog: false,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom
    })
    this.updateVisibleMarkersSet(this.static.parentMap)
    if (this.spaProps.disableDefaultLayers !== false) {
      layerFactory.tile({
        name: 'defaultLight',
        options: {
          visible: this.currentLayerName === 'default' && !this.isDarkTheme,
          urlTemplate:
            this.spaProps.urlTemplateLight ||
            'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
        },
        map: this.static.parentMap,
        layersRegistry: this.static.layersMap
      })
      layerFactory.tile({
        name: 'defaultDark',
        options: {
          visible: this.currentLayerName === 'default' && this.isDarkTheme,
          urlTemplate:
            this.spaProps.urlTemplateDark ||
            'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
        },
        map: this.static.parentMap,
        layersRegistry: this.static.layersMap
      })
    }

    if (this.spaProps.layers?.length > 0) {
      for (const layer of this.spaProps.layers) {
        layerFactory?.[layer.type]?.({
          name: layer.title,
          options: {
            visible: this.currentLayerName === layer.title,
            ...layer.options
          },
          map: this.static.parentMap,
          layersRegistry: this.static.layersMap
        })
      }
    }
    this.static.popupLayer = new VectorLayer('popupLayer', { zIndex: 32 }).addTo(
      this.static.parentMap
    )
    new VectorLayer('sectors-vector-layer', {
      zIndex: 29,
      hitDetect: false
    }).addTo(this.static.parentMap)

    new VectorLayer('markersLayer', {
      zIndex: 30,
      hitDetect: false,
      forceRenderOnZooming: true,
      forceRenderOnMoving: true,
      forceRenderOnRotating: true
    }).addTo(this.static.parentMap)
    new VectorLayer('tooltipsLayer', {
      zIndex: 31,
      hitDetect: false,
      forceRenderOnZooming: false,
      forceRenderOnMoving: false,
      forceRenderOnRotating: false
    }).addTo(this.static.parentMap)
    setWidgetMap(this.widgetUUID, this.static.parentMap)
    this.currentRoutePath = history.location.pathname
    this.$store.commit('setValue', ['currentZoom', Math.round(this.static.parentMap.getZoom())])
    this.static.parentMap.on('zooming', this.onZoom)
    // @ts-ignore
    document.addEventListener('wheel', this.switchZoomMode, true)
    this.static.parentMap.on('moveend pitchend dragrotateend zoomend', (event: any) => {
      debounce(
        () => {
          this.updateVisibleMarkersSet(event.target)
        },
        100,
        'calcTree'
      )
    })
    this.spaProps.isNeedToPushPositionsInUrl !== false &&
      this.static.parentMap.on('moveend pitchend dragrotateend zoomend', (event: any) => {
        debounce(() => {
          if (history.location.pathname === this.currentRoutePath) {
            const currentMapView = this.static.parentMap.getView()
            this.$emit('update:mapView', this.static.parentMap.getView())
            const query = SearchParams.parse(history.location.search)

            history.replace({
              search: SearchParams.stringify({
                ...query,
                zoom: Math.floor(Number(currentMapView.zoom)),
                pitch: Math.floor(Number(currentMapView.pitch)),
                bearing: Math.floor(Number(currentMapView.bearing)),
                center: coordinatesTolocation(currentMapView.center)
              })
            })
          }
        }, 300)
      })
    this.static.parentMap.on('click', this.clickOnMap)
  },
  destroyed() {
    window.removeEventListener('keydown', this.plusMinusClickHandler)
    document.removeEventListener('wheel', this.switchZoomMode)
    this.static.parentMap.off('zooming', this.onZoom)
  }
})
