import {
  Layer,
  TileLayer,
  SpatialReference,
  WMSTileLayer,
  Map as MaptalksMap
  // @ts-ignore
} from 'maptalks'
// @ts-ignore
import { MapboxglLayer } from 'maptalks.mapboxgl'

const DEFAULT_LAYERS_PROPS_MAP = {
  default: {
    hitDetect: false,
    forceRenderOnZooming: true,
    forceRenderOnMoving: true,
    forceRenderOnRotating: true,
    subdomains: ['a', 'b', 'c', 'd'],
    maxAvailableZoom: 20
  },
  wms: {
    tileSystem: [1, -1, -180, 90],
    spatialReference: {
      projection: 'EPSG:3857'
    },
    crs: 'EPSG:3857',
    layers: 'OSM-WMS',
    styles: '',
    version: '1.3.0',
    format: 'image/png',
    transparent: true,
    uppercase: true
  }
} as const

type FactoryProperties = {
  name: string
  options: Record<string, unknown>
  map: MaptalksMap
  layersRegistry: Record<string, Layer>
}

export const layerFactory = {
  tile: ({ name, options, map, layersRegistry }) => {
    try {
      ;(layersRegistry[name] = new TileLayer(name, {
        ...DEFAULT_LAYERS_PROPS_MAP.default,
        ...options
      })).addTo(map)
    } catch (error) {
      console.error(error)
    }
  },
  mapbox: ({ name, options, map, layersRegistry }) => {
    try {
      layersRegistry[name] = new MapboxglLayer(name, {
        glOptions: {
          style: options.styleUrl
        },
        ...options
      })
      layersRegistry[name].once('show', () => {
        map.addLayer(layersRegistry[name])
        layersRegistry[name]._renderer.clear = () => {}
      })
      options.visible && layersRegistry[name].fire('show')
    } catch (error) {
      console.error(error)
    }
  },
  wms: ({ name, options, map, layersRegistry }) => {
    try {
      ;(layersRegistry[name] = new WMSTileLayer(name, {
        ...DEFAULT_LAYERS_PROPS_MAP.wms,
        ...options
      })).addTo(map)
    } catch (error) {
      console.error(error)
    }
  },
  wmts: ({ name, options, map, layersRegistry }) => {
    SpatialReference.loadWMTS(options.url, (err: any, conf: any) => {
      if (err) {
        throw new Error(err)
      }
      const params = conf[0]
      ;(layersRegistry[name] = new TileLayer(name, {
        ...params,
        ...options
      })).addTo(map)
    })
  },
  arcGIS: ({ name, options, map, layersRegistry }) => {
    SpatialReference.loadArcgis(options.url, (err: any, conf: any) => {
      if (err) {
        throw new Error(err)
      }
      const ref = conf.spatialReference
      ref.projection = 'EPSG:3857'
      ref.fullExtent = null
      ;(layersRegistry[name] = new TileLayer(name, {
        tileSystem: conf.tileSystem,
        tileSize: conf.tileSize,
        ...options
      })).addTo(map)
    })
  }
} as Record<string, (p: FactoryProperties) => void>
