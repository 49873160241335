
import Vue from 'vue'
// @ts-ignore
import ResizeObserver from 'resize-observer-polyfill'
import CreateLayoutButton from './CreateLayoutButton.vue'
import { te } from '@/translations'

type NodeOptions = {
  id: string
  label: string
  icon: string
  command: () => void
  items?: NodeOptions[]
  createLayoutButton: Boolean
}
export default Vue.extend({
  name: 'NestedMenu',
  props: {
    clickedOn: Array,
    nodeOptions: Array as () => NodeOptions[]
  },
  data() {
    return {
      resizeObserver: {} as any
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      const nodeOption = this.nodeOptions.find((o) => this.clickedOn.includes(o.id))
      if (nodeOption) {
        const nodeRef = this.$refs[nodeOption.id]
        const nodeElement = (Array.isArray(nodeRef) ? nodeRef[0] : nodeRef) as HTMLElement
        nodeElement && nodeElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
      }
    })
    const nestedMenu = this.$refs?.nestedMenu as HTMLVideoElement
    this.resizeObserver.observe(nestedMenu.parentElement)
  },
  methods: {
    te,
    setClickedOn(val: string[]) {
      this.$emit('update:clickedOn', val)
    }
  },
  components: { CreateLayoutButton }
})
