
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'GeoSearch',
  props: {
    storeProp: Object,
    initialSearchValue: String,
    filterOnSearch: Boolean,
    onSearchChange: Function,
    onSelectEntity: Function,
    onToggleFiltering: Function
  },
  data() {
    return {
      unmount: () => {}
    }
  },
  computed: {
    ...mapState(['spaParent'])
  },
  methods: {
    mountWidget() {
      try {
        const ref = this.$refs['geo-search']
        const geoSearch = this.storeProp.state.spaParent.areas.find((e: any) => e.name === 'GeoSearch')
        if (geoSearch && ref) {
          return this.storeProp.state.spaParent.mountChildren(
            ref,
            geoSearch.children.map((e:any) => {
              return {
                ...e,
                props: {
                  ...e.props,
                  initialSearchValue: this.initialSearchValue,
                  onEnter: this.onSearchChange,
                  onSelect: this.onSelectEntity,
                  filterFeature: {
                    onToggleFiltering: this.onToggleFiltering,
                    filterOnSearch: this.filterOnSearch
                  }
                }
              }
            })
          )
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {
    this.unmount = this.mountWidget()
  },
  beforeDestroy() {
    this.unmount()
  }
})
