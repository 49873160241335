
import { getPermissionsByIdsMap } from '@netvision/lib-api-gateway'
import { CubeQuery, ISearchQueryPayload } from '@netvision/lib-api-repo'
import { history, SearchParams } from '@netvision/lib-history'
import InlineMessage from 'primevue/inlinemessage'
import Sidebar from 'primevue/sidebar'
import BolidDeviceCard from './common/BolidDeviceCard.vue'
import CameraCard from './common/CameraCard.vue'
import Mixins from './common/Mixins'
import { mapMutationsTyped, mapStateTyped } from '@/store'
import { get as getFromIDB, set as setInIDB } from 'idb-keyval'
import QueryFilters from './common/QueryFilters.vue'
import TMarker from './common/TMarker.vue'
import MaptalksMap from './common/MapTalksMap.vue'
import FPSStatistic from './common/FPSStatistic.vue'
import ComplexObjectOnMap from './ComplexObjectOnMap.vue'
import EntityMarker from './common/EntityMarker.vue'
import { name as appName } from '../../package.json'
import { parseQ } from '../ngsi-query'
import { slice, isEqual, chunk } from 'lodash'
import { makeObjectsTree, debounce, locationToCoordinates } from '@/utils'
import LayersMenu from './common/LayersMenu.vue'
import { RangeTree } from '@/RangeTree'
import ToggleButton from 'primevue/togglebutton'
import GeoSearch from './common/GeoSearch.vue'
import { getWidgetMap } from '@/mapStore'
// @ts-ignore
import GeoViewport from '@mapbox/geo-viewport'

const Q_CLUSTER_NAME = 'qCluster'

export default Mixins.extend({
  name: 'Maptalks',
  components: {
    GeoSearch,
    BolidDeviceCard,
    CameraCard,
    QueryFilters,
    TMarker,
    ComplexObjectOnMap,
    MaptalksMap,
    EntityMarker,
    Sidebar,
    FPSStatistic,
    LayersMenu,
    InlineMessage,
    ToggleButton
  },
  data() {
    return {
      activeFilters: { title: '' } as {
        [key: string]: string | string[]
      },
      loadingStatus: 'init' as 'init' | 'loading' | 'complete' | 'error',
      userId: '',
      Q_CLUSTER_NAME,
      lastContainedPosition: null as any,
      currentRoutePath: '',
      themeUnsubscribe: () => {},
      maxClusterRadius: 110,
      clustersList: [] as any,
      mapView: {
        center: [50.1396857, 53.2271572],
        zoom: 14,
        pitch: 45,
        bearing: 0
      },
      modes: [
        { label: '3D', value: 'gl' },
        { label: '2D', value: 'canvas' }
      ],
      localSelectMode: 'gl',
      prevRoute: {} as any,
      highlightUnsubscribe: () => {},
      unlistenQueryParams: () => {},
      qEntities: null as IEntity[] | null,
      lastQ: '',
      showQLayer: false,
      showClearFiltersButton: false,
      filterEntitiesOnSearch: false,
      initialSearchValue: ''
    }
  },
  watch: {
    activeFilters: {
      handler(val) {
        const selectedFilters = Object.keys(val).filter((f) => f !== 'title')
        this.showClearFiltersButton = !!selectedFilters.length
      },
      deep: true
    },
    clusters(val) {
      this.clustersList = Object.values(val).reduce((acc, cluster) => {
        return [...(acc as any), ...(cluster as any[])]
      }, [] as any) as any
      this.setValue([
        'clusteredMarkersIds',
        this.clustersList.reduce((acc: string[], { children }: any) => {
          return [...acc, ...children.map(({ id }: any) => id)]
        }, [])
      ])
    },
    localSelectMode(val, prevVal) {
      if (val != null) {
        localStorage.setItem(`${appName}-map-mode`, val)
        this.setValue(['selectedMode', val])
      } else {
        this.localSelectMode = prevVal
      }
    }
  },
  computed: {
    ...mapStateTyped([
      'api',
      'spaProps',
      'allMapEntitiesTree',
      'permissionScopes',
      'showArchivePlayerContainer',
      'showAssignmentDialogContainer',
      'currentOpenedComplexObject',
      'leafEntityTypes',
      'areaEntityTypes',
      'currentEntityId',
      'clusteringByType',
      'clusters',
      'highlightedIds',
      'highlighted',
      'prevRoutes',
      'currentLayerName',
      'markersRangeTree',
      'visibleMarkersSetTracker',
      'filteredEntitiesTracker',
      'popupMode',
      'popupFeatureToggle',
      'openedPopupsIds',
      'maxOpenedPopups',
      'widgetUUID'
    ]),
    popupModeOptions(): { icon: string; value: 'single' | 'multiple'; label: string }[] {
      return [
        {
          icon: 'mdi mdi-24px mdi-map-marker',
          value: 'single',
          label: this.te('button.singlePopup')
        },
        {
          icon: 'mdi mdi-24px mdi-map-marker-multiple',
          value: 'multiple',
          label: this.te('button.multiplePopup')
        }
      ]
    },
    localPopupMode: {
      get(): boolean {
        return this.popupMode === 'single'
      },
      set(val: boolean) {
        this.setValue(['popupMode', val ? 'single' : 'multiple'])
      }
    },
    filteredEntities(): IEntity[] {
      const filtered =
        this.qEntities === null
          ? this.allMapEntitiesTree.filter((entity) => {
              return Object.entries(this.activeFilters).every(([key, value]) => {
                if (entity[key] !== undefined) {
                  if (key === 'title' && typeof value === 'string') {
                    const searchedRegExp = new RegExp(value, 'i')
                    return searchedRegExp.test(
                      `${entity.title} ${Array.from(
                        new Set([
                          (entity.streetAddress as string)?.trim(),
                          (entity.addressLocality as string)?.trim()
                        ])
                      )
                        .filter((el) => el)
                        .join(', ')}` as string
                    )
                  } else if (typeof value === 'string') {
                    const searchedRegExp = new RegExp(value, 'i')
                    return searchedRegExp.test(entity[key] as string)
                  } else if (Array.isArray(value)) {
                    return value.includes(entity[key] as string)
                  }
                }
                return false
              })
            })
          : this.qEntities
      this.setValue(['filteredEntitiesSet', new Set(filtered)])
      this.setValue(['filteredEntitiesIds', new Set(filtered.map(({ id }) => id))])
      this.setValue(['filteredEntitiesTracker', this.filteredEntitiesTracker + 1])
      return filtered
    },
    filteredIds(): Set<string> {
      return new Set(this.filteredEntities.map(({ id }) => id))
    },
    isCOVisible(): boolean {
      return !this.activeFilters.type?.length || this.activeFilters.type.includes('ComplexObject')
    }
  },
  methods: {
    ...mapMutationsTyped(['setValue', 'closeAllPopups']),
    debounce,
    isEqual,
    canI(scope: string, id: string | null = null) {
      const scopes = this.permissionScopes.get(id)
      return Array.isArray(scopes) ? scopes.includes(scope) : false
    },
    clearAllFilters() {
      const title = this.activeFilters.title
      this.activeFilters = { title }
    },
    isClusterTransparent(cluster: Cluster) {
      if (cluster.id && cluster.id.includes(Q_CLUSTER_NAME)) return false
      if (this.qEntities) return true
      return !cluster.children.some(({ id }) => {
        if (this.highlightedIds.length > 0) {
          return this.highlightedIds.includes(id)
        }
        return this.filteredIds.has(id)
      })
    },
    async fetchAllEntities<T extends IMapEntity>(): Promise<T[]> {
      if (!this.api.cubeGetEntities) {
        throw new Error("cubeGetEntities method doesn't exist in api")
      }
      if (!this.spaProps.cubeListQuery) {
        throw new Error("cubeListQuery property doesn't provide in spaProps")
      }

      try {
        const { results } = await this.api.cubeGetEntities<T>(
          this.spaProps.cubeListQuery as CubeQuery
        )
        return results
      } catch (error) {
        console.error(error)
        throw new Error('Something went wrong')
      }
    },
    async fetchFeatureCollection<T extends IMapEntity>(): Promise<T[]> {
      try {
        const { results } = await this.api.getEntitiesList<T>({
          limiter: { type: 'FeatureCollection', limit: 1000 }
        })
        return results
      } catch (error) {
        console.error(error)
        return []
      }
    },
    async fetchPermissionsBatch(ids: string[]) {
      return (
        (await Promise.all(chunk(ids, 100).map(getPermissionsByIdsMap)))
          // eslint-disable-next-line no-sequences
          .reduce((m, map) => (map.forEach((v, k) => m.set(k, v)), m), new Map())
      )
    },
    async fetchAssignmentsTypes() {
      try {
        const { results } = await this.api.getEntitiesList<AssignmentType>({
          limiter: {
            type: 'AssignmentType',
            keyValues: true,
            limit: 1000
          }
        })
        this.setValue(['assignmentTypes', results])
      } catch (error) {
        this.errorToast(error)
      }
    },
    async fetchAssignmentsList() {
      try {
        const { results } = await this.api.getEntitiesList<Assignment>({
          limiter: {
            type: 'Assignment',
            keyValues: true,
            limit: 1000
          }
        })
        this.setValue([
          'permissionScopes',
          new Map([
            ...this.permissionScopes,
            ...(await this.fetchPermissionsBatch(results.map((e) => e.id)))
          ])
        ])
        this.setValue(['analytics', results])
      } catch (error) {
        this.errorToast(error)
      }
    },
    async fetchAssignments() {
      const readAssignment: string[] = await this.api.getPermissions([
        'ReadAssignment',
        'ReadAssignmentGroup'
      ])
      if (readAssignment.includes('ReadAssignment')) {
        await this.fetchAssignmentsTypes()
        await this.fetchAssignmentsList()
        readAssignment.includes('ReadAssignmentGroup') && (await this.fetchAssignmentGroups())
      }
    },
    async fetchAssignmentGroups() {
      try {
        const { results } = await this.api.getEntitiesList<IAssignmentGroup>({
          limiter: {
            type: 'AssignmentGroup',
            keyValues: true,
            limit: 1000
          }
        })
        this.setValue([
          'permissionScopes',
          new Map([
            ...this.permissionScopes,
            ...(await this.fetchPermissionsBatch(results.map((e) => e.id)))
          ])
        ])
        this.setValue(['assignmentGroups', results])
      } catch (error) {
        this.errorToast(error)
      }
    },
    async fetchQ(q: string) {
      this.lastQ = q
      const qStatement = parseQ(q)
      try {
        const options = {
          type: [...new Set([...this.areaEntityTypes, ...this.leafEntityTypes])].join(','),
          keyValues: true,
          count: true,
          limit: 1000
        } as any
        const { results } = await this.api.getEntitiesList<IAssignmentGroup>({
          limiter: options,
          filter: {
            q: Object.entries(qStatement).map(
              ([key, val]) =>
                ({
                  key,
                  value: Object.entries(val)[0][1],
                  operator: Object.entries(val)[0][0]
                } as ISearchQueryPayload)
            )
          }
        })
        return results || []
      } catch (error) {
        console.error(error)
        return []
      }
    },
    beforeMapRoute(_: any, from: any, next: any) {
      let { complexObject } = from.query
      complexObject =
        typeof complexObject === 'string'
          ? decodeURI(complexObject).replaceAll('"', '')
          : complexObject
      if (
        complexObject &&
        this.currentOpenedComplexObject.id &&
        complexObject !== this.currentOpenedComplexObject.id
      ) {
        const prevRoutes = [...this.prevRoutes, from]
        this.setValue(['prevRoutes', prevRoutes])
      }
      next()
    },
    clusterColor(cluster: any) {
      let crucial = { value: 0, color: cluster.children[0].color }
      cluster.children.forEach(({ id }: any) => {
        const highlighted = this.highlighted[id]

        if (highlighted?.value && crucial.value < highlighted?.value) {
          crucial = { value: highlighted?.value, color: highlighted?.color || '#FF0000' }
        }
      })
      return crucial.color
    },
    async fetchAllPermissions(entities: IEntity[]): Promise<Map<IEntity['id'], string[]>> {
      let [length, start, end, idsBatches] = [entities.length, 0, 100, [] as IEntity['id'][][]]
      // there is limitition for request max length
      while (length > 0) {
        idsBatches.push(slice(entities, start, end).map((e) => e.id))
        start += 100
        end += 100
        length -= 100
      }
      return new Map(
        (
          await Promise.allSettled(
            idsBatches.map((batch) => {
              return getPermissionsByIdsMap(batch)
            })
          )
        ).reduce((acc, value) => {
          return value.status === 'fulfilled' ? [...acc, ...value.value] : acc
        }, [] as any)
      )
    },
    addSearchValueInQuery(search: string) {
      history.replace({
        search: SearchParams.stringify({
          ...SearchParams.parse(history.location.search),
          search
        })
      })
    },
    onSearchFilter(val: string) {
      this.addSearchValueInQuery(val)
      if (this.filterEntitiesOnSearch) {
        this.activeFilters.title = val
      } else {
        this.activeFilters.title = ''
      }
      return this.filterAllMapEntitiesTree(val)
    },
    onSelectEntity({
      location,
      title,
      boundingbox
    }: {
      location: string
      title: string
      boundingbox?: string[]
    }) {
      this.addSearchValueInQuery(title)
      if (this.filterEntitiesOnSearch) {
        this.activeFilters.title = title
      }
      const parentMap = getWidgetMap(this.widgetUUID)
      const { width, height } = parentMap.getSize()
      let zoom = 16

      if (boundingbox) {
        const [swLat, neLat, swLng, neLng] = boundingbox
        const bbox = [swLng, swLat, neLng, neLat]
        zoom = GeoViewport.viewport(bbox, [width, height]).zoom
      }

      parentMap.animateTo({
        center: locationToCoordinates(location),
        zoom
      })
    },
    onFilteringFlagChange(flag: boolean) {
      if (!flag) {
        this.activeFilters.title = ''
      }
      this.filterEntitiesOnSearch = flag
      localStorage.setItem(`${appName}:map-filtering`, JSON.stringify(this.filterEntitiesOnSearch))
    },
    filterAllMapEntitiesTree(title: string): IEntity[] {
      return this.allMapEntitiesTree.filter((entity) => {
        const searchedRegExp = new RegExp(title, 'i')
        return searchedRegExp.test(
          `${entity.title} ${Array.from(
            new Set([
              (entity.streetAddress as string)?.trim(),
              (entity.addressLocality as string)?.trim()
            ])
          )
            .filter((el) => el)
            .join(', ')}` as string
        )
      })
    }
  },
  beforeMount() {
    const spaProps = this.spaProps as SPAProps
    this.$router.beforeResolve(this.beforeMapRoute)
    this.setValue(['maxZoom', spaProps.maxZoom || 23])
    this.localSelectMode =
      localStorage.getItem(`${appName}-map-mode`) || (spaProps.view3d && 'gl') || 'canvas'
    this.filterEntitiesOnSearch = JSON.parse(
      localStorage.getItem(`${appName}:map-filtering`) || 'true'
    )
    this.setValue(['selectedMode', this.localSelectMode])
    this.setValue(['clusteringByType', spaProps.clusteringByType || false])
    this.setValue(['maxClusterRadius', spaProps.maxClusterRadius || this.maxClusterRadius])
    const query = SearchParams.parse(history.location.search)

    if (query.search) {
      this.initialSearchValue = query.search as string
      this.onSearchFilter(query.search as string)
    }

    const cameraFromQuery = {} as { [key: string]: any }
    ;['bearing', 'pitch', 'zoom'].forEach((e: string) => {
      const field = query[e]
      if (field !== undefined) {
        cameraFromQuery[e] = Number(field)
      }
    })
    if (query.center) {
      cameraFromQuery.center = this.locationToCoordinates(query.center as string)
    }
    this.mapView = {
      ...this.mapView,
      ...spaProps.camera,
      ...cameraFromQuery
    }
    // HIGHLIGHT
    if ('highlightIt' in spaProps) {
      const { highlightIt } = spaProps

      this.setValue(['highlighted', highlightIt.get()])
      this.highlightUnsubscribe = highlightIt.subscribe((next) => {
        this.setValue(['highlightedIds', Object.keys(next)])
        this.setValue(['highlighted', next])
      })
    }
    // Query Params
    if ('queryParam' in spaProps) {
      const { queryParam } = spaProps
      let lastQ: undefined | string
      this.unlistenQueryParams = history.listen(async (location) => {
        const { [queryParam]: q } = SearchParams.parse(location.search)
        if (typeof q === 'string' && lastQ !== q) {
          lastQ = q
          this.qEntities = await this.fetchQ(q)
        } else if (q === undefined && lastQ !== undefined) {
          lastQ = undefined
          this.qEntities = null
        }
      })
    }
    const themeEl = document.getElementById('theme') as HTMLLinkElement & {
      setTheme: () => void
    }
    this.setValue(['isDarkTheme', themeEl.getAttribute('theme-name') === 'dark'])
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === 'dark')
      themeEl.addEventListener('update', listener)
      return () => themeEl.removeEventListener('update', listener)
    }
    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.setValue(['isDarkTheme', newValue])
    })
  },
  async mounted() {
    if ('getUserInfo' in this.api) {
      try {
        const res = await this.api.getUserInfo()
        if (res && 'userId' in res) {
          this.userId = res.userId
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (!this.spaProps) return
    this.fetchAssignments()

    if ('queryParam' in this.spaProps) {
      const { queryParam } = this.spaProps
      const { [queryParam]: q } = SearchParams.parse(history.location.search)
      if (typeof q === 'string') this.qEntities = await this.fetchQ(q)
    }
    // Check entities in cache
    const allMapEntitiesTree = await getFromIDB(
      `${appName}${history.location.pathname}${this.userId}-objectsTree`
    )

    allMapEntitiesTree && this.setValue(['allMapEntitiesTree', allMapEntitiesTree])
    this.setValue(['markersRangeTree', new RangeTree(allMapEntitiesTree || [])])
    !allMapEntitiesTree && (this.loadingStatus = 'loading')

    // Work with permissions
    const permissions = await getFromIDB(
      `${appName}${history.location.pathname}${this.userId}-permissions`
    )

    permissions &&
      this.setValue(['permissionScopes', new Map([...this.permissionScopes, ...permissions])])

    try {
      // get fresh entities from backend
      this.loadingStatus = 'loading'
      const [entities, featureCollection] = Array.from(
        await Promise.all([this.fetchAllEntities(), this.fetchFeatureCollection()])
      )
      this.setValue(['markersRangeTree', new RangeTree(entities || [])])
      this.setValue(['visibleMarkersSetTracker', this.visibleMarkersSetTracker + 1])
      const objectsTree = makeObjectsTree(
        [...entities, ...featureCollection],
        ['ComplexObject', ...this.areaEntityTypes]
      )
      this.setValue(['allMapEntitiesTree', objectsTree])
      setInIDB(`${appName}${history.location.pathname}${this.userId}-objectsTree`, objectsTree)
      const permissionScopes = await this.fetchAllPermissions(entities)
      permissionScopes &&
        setInIDB(
          `${appName}${history.location.pathname}${this.userId}-permissions`,
          permissionScopes
        )
      this.setValue(['permissionScopes', new Map([...this.permissionScopes, ...permissionScopes])])
      this.loadingStatus = 'complete'
    } catch (error) {
      console.error(error)
      this.loadingStatus = 'error'
    }
  },
  destroyed() {
    this.themeUnsubscribe()
    this.unlistenQueryParams()
    this.highlightUnsubscribe()
    // @ts-ignore
    this.$router.resolveHooks = this.$router.resolveHooks.filter(
      (e: any) => !e.name.includes('beforeMapRoute')
    )
  }
})
