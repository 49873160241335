
import Vue from 'vue'
// @ts-ignore
import { Sector, Polygon } from 'maptalks'

import { getWidgetMap } from '@/mapStore'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'TSector',
  props: {
    visible: { type: Boolean, required: false, default: undefined },
    center: Array,
    radius: Number,
    startAngle: { type: [Number, String] },
    endAngle: { type: [Number, String] },
    options: Object,
    zIndex: Number
  },
  data: () => ({}),
  created() {
    this.static = {
      parentMap: {} as any,
      layer: {} as any,
      sector: {} as any
    }
  },
  computed: {
    ...mapState(['widgetUUID'])
  },
  methods: {
    calcShell() {
      let startAngle = Number(this.startAngle)
      const endAngle = Number(this.endAngle)
      if (startAngle > endAngle) {
        startAngle = startAngle - 360
      }
      return new Sector(this.center, this.radius || 1, startAngle, endAngle || 0)
        .getShell()
        .map(({ x, y }: { x: number; y: number }) => [x, y])
    }
  },
  watch: {
    options: {
      handler(val) {
        if (val.symbol) {
          this.static?.sector?.updateSymbol?.(val.symbol)
        }
      },
      deep: true
    },
    radius() {
      this.static.sector.setCoordinates(this.calcShell())
    },
    visible(val) {
      if (val) {
        this.static?.sector?.show?.()
      } else {
        this.static?.sector?.hide?.()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      try {
        this.static.parentMap = getWidgetMap(this.widgetUUID)
        this.static.sector = new Polygon(this.calcShell(), this.options)
        this.static.parentMap.getLayer('sectors-vector-layer')?.addGeometry(this.static.sector)
        this.static.sector.on('click dblclick', () => {
          this.$emit('click')
        })
      } catch (error) {
        console.error(error)
      }
    })
  },
  destroyed() {
    this.static.sector.remove()
  }
})
