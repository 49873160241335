var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"nestedMenu",staticClass:"nested-menu"},_vm._l((_vm.nodeOptions),function(nodeOption){return _c('div',{key:nodeOption.id},[_c('div',{ref:`${nodeOption.id}`,refInFor:true,staticClass:"p-d-flex nested-menu__node",class:{
        'nested-menu__node_active': _vm.clickedOn.includes(nodeOption.id),
      },on:{"click":() => {
          if (nodeOption.command) {
            nodeOption.command()
          } else {
            _vm.setClickedOn(_vm.clickedOn.includes(nodeOption.id) ? [] : [nodeOption.id])
          }
        }}},[_c('i',{staticClass:"mdi mdi-18px p-mr-1",class:nodeOption.items
            ? _vm.clickedOn.includes(nodeOption.id)
              ? 'mdi-24px mdi-chevron-down'
              : 'mdi-24px mdi-chevron-right'
            : nodeOption.icon}),_c('span',{domProps:{"textContent":_vm._s(nodeOption.label)}}),_c('div',{staticStyle:{"flex-grow":"1"}}),(nodeOption.createLayoutButton)?_c('CreateLayoutButton',{attrs:{"ids":[nodeOption.id],"message":_vm.te('message.createObjectLayout'),"title":nodeOption.label,"iconOnly":"","tooltip":_vm.te('button.createCamerasLayout')},on:{"end":function($event){return _vm.$emit('end')}}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.clickedOn.includes(nodeOption.id)),expression:"clickedOn.includes(nodeOption.id)"}]},_vm._l((nodeOption.items),function(innerEntitie){return _c('div',{key:innerEntitie.id},[_c('div',{staticClass:"p-d-flex nested-menu__leaf",class:{
              'nested-menu__leaf_active': _vm.clickedOn.includes(innerEntitie.id),
            },on:{"click":innerEntitie.command}},[_c('i',{staticClass:"mdi mdi-18px p-mr-1",class:innerEntitie.icon}),_c('span',{domProps:{"textContent":_vm._s(innerEntitie.label)}})])])}),0)])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }