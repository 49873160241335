export const ru = {
  from: 'из',
  button: {
    ok: 'ОК',
    yes: 'Да',
    no: 'Нет',
    delete: 'Удалить',
    save: 'Сохранить',
    create: 'Создать',
    cancel: 'Отмена',
    createCamerasLayout: 'Создать раскладку',
    createLayerLayout: 'Создать раскладку слоя',
    singlePopup: 'Одиночное окно',
    multiplePopup: 'Множественное окно'
  },
  error: 'Ошибка',
  zoomIn: 'Приблизить',
  message: {
    fetchError: 'Ошибка получения данных',
    deleteTitle: 'Удалить',
    excludeTitile: 'Исключить',
    createPermission: 'Создать разрешение',
    editPermission: 'Изменить разрешение',
    deleteEntity: 'Удалить без возможности восстановления?',
    deleteUserFromGroup: 'Исключить пользователя из группы?',
    deletePermission: 'Удалить разрешение?',
    createCamerasLayout: 'Создать раскладку',
    createComplexObjectLayout: 'Создать раскладку всего комплексного объекта?',
    createLayerLayout: 'Создать раскладку текущего слоя?',
    createObjectLayout: 'Создать раскладку данного объекта?',
    openedPopups: 'открыто',
    streamsNotWorking: 'нерабочих потоков',
    grabToDragg: 'Нажмите и перетащите'
  },
  form: {
    name: 'Название',
    choose: 'Выбрать',
    activationStatus: 'Уведомлять о статусе'
  },
  wrongStatus: 'Неверный статус',
  search: 'Поиск',
  select: 'Выбрать',
  nothingFound: 'Ничего не найдено',
  dialog: {
    editeBolidSubscription: 'Изменить уведомление о статусе',
    createBolidSubscription: 'Добавить уведомление о статусе',
    deleteEntity: 'Удалить объект',
    createEntity: 'Создать объект',
    editEntity: 'Редактировать объект',
    coordinatesMap: 'Укажите координаты'
  },
  back: 'Назад',
  floors: 'Этажи',
  open: 'Открыть',
  loading: 'Обновление данных карты',
  emptyHistory: 'Нет истории изменения статусов',
  wrongStatusMessge: 'В коде статуса ошибка',
  calendarButtons: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    week: 'Неделя',
    month: 'Месяц'
  },
  default: 'Базовый',
  geo: 'Геоподложка'
} as const
