
import Vue from 'vue'

// @ts-ignore
import { GeoJSON, VectorLayer } from 'maptalks'
import { getWidgetMap } from '@/mapStore'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'TGeoJSON',
  props: {
    json: Object,
    zIndex: Number,
    symbol: Object
  },
  data: () => ({}),
  created() {
    this.static = {
      parentMap: {} as any,
      layer: {} as any
    }
  },
  computed: {
    ...mapState(['widgetUUID'])
  },
  async mounted() {
    const geometries = this.json.features.map((el: any) =>
      GeoJSON.toGeometry(el, (geom: any) => {
        geom.setSymbol(this.symbol)
      })
    )
    this.static.parentMap = getWidgetMap(this.widgetUUID)
    this.static.layer = new VectorLayer(this.json.id, { zIndex: this.zIndex || 2 })
      .addGeometry(geometries)
      .addTo(this.static.parentMap)
  },
  destroyed() {
    this.static.parentMap.removeLayer(this.static.layer)
  }
})
