
import Vue from 'vue'
import Map from './components/Map.vue'
import { debounce, uuid } from './utils'
import { repositoryGetter } from '@netvision/lib-api-repo'

export default Vue.extend({
  name: 'App',
  components: {
    Map
  },
  beforeMount() {
    this.$store.commit('reset')
    this.$store.commit('setValue', ['api', repositoryGetter(this.$parent?.props?.lib)])
    this.$store.commit('setValue', ['widgetUUID', uuid()])
    this.$store.commit('setValue', ['spaParent', this.$parent])
    this.$store.commit('setValue', ['spaProps', this.$parent?.props])
    this.$store.commit('setValue', ['areaEntityTypes', this.$parent?.props.areaEntityTypes ?? []])
    this.$store.commit('setValue', ['maxOpenedPopups', this.$parent?.props.maxOpenedPopups || 5])
    this.$store.commit('setValue', [
      'popupFeatureToggle',
      this.$parent?.props.popupFeatureToggle || true
    ])
  },
  methods: {
    onResize({ height, width }: any) {
      debounce(() => {
        this.$store.commit('setValue', ['fullHeight', height])
        this.$store.commit('setValue', ['fullWidth', width])
      }, 200)
    }
  },
  mounted() {
    const el = this.$el as HTMLElement
    this.$store.commit('setValue', ['fullWidth', el.offsetWidth])
    this.$store.commit('setValue', ['fullHeight', el.offsetHeight])
  }
})
