
import Vue from 'vue'
// @ts-ignore
import Stats from 'stats.js'

export default Vue.extend({
  name: 'FPSStatistic',
  data: () => ({}),
  methods: {},
  mounted() {
    const stats = new Stats()
    stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
    stats.dom.style.right = '0'
    stats.dom.style.left = 'initial'
    document
      .getElementById(`anchor-for-fps${this._uid}`)!
      .appendChild(stats.dom)
    function animate() {
      stats.begin()
      stats.end()
      requestAnimationFrame(animate)
    }
    requestAnimationFrame(animate)
  }
})
