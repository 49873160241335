(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/inputtext"), require("primevue/slider"), require("primevue/inlinemessage"), require("primevue/scrollpanel"), require("primevue/togglebutton"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("@netvision/lib-history"), require("maptalks"), require("vue-router"), require("primevue/toastservice"), require("primevue/progressspinner"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/selectbutton"), require("primevue/panelmenu"), require("primevue/sidebar"), require("@netvision/lib-api-gateway"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/overlaypanel"), require("primevue/button"), require("primevue/menu"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/inputtext", "primevue/slider", "primevue/inlinemessage", "primevue/scrollpanel", "primevue/togglebutton", "primevue/dropdown", "primevue/tooltip", "single-spa-vue", "@netvision/lib-history", "maptalks", "vue-router", "primevue/toastservice", "primevue/progressspinner", "vue", "@netvision/lib-api-repo", "primevue/selectbutton", "primevue/panelmenu", "primevue/sidebar", "@netvision/lib-api-gateway", "primevue/toast", "primevue/progressbar", "primevue/dialog", "primevue/overlaypanel", "primevue/button", "primevue/menu"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/inputtext"), require("primevue/slider"), require("primevue/inlinemessage"), require("primevue/scrollpanel"), require("primevue/togglebutton"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("@netvision/lib-history"), require("maptalks"), require("vue-router"), require("primevue/toastservice"), require("primevue/progressspinner"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/selectbutton"), require("primevue/panelmenu"), require("primevue/sidebar"), require("@netvision/lib-api-gateway"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/overlaypanel"), require("primevue/button"), require("primevue/menu")) : factory(root["primevue/inputtext"], root["primevue/slider"], root["primevue/inlinemessage"], root["primevue/scrollpanel"], root["primevue/togglebutton"], root["primevue/dropdown"], root["primevue/tooltip"], root["single-spa-vue"], root["@netvision/lib-history"], root["maptalks"], root["vue-router"], root["primevue/toastservice"], root["primevue/progressspinner"], root["vue"], root["@netvision/lib-api-repo"], root["primevue/selectbutton"], root["primevue/panelmenu"], root["primevue/sidebar"], root["@netvision/lib-api-gateway"], root["primevue/toast"], root["primevue/progressbar"], root["primevue/dialog"], root["primevue/overlaypanel"], root["primevue/button"], root["primevue/menu"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__025a__, __WEBPACK_EXTERNAL_MODULE__12a1__, __WEBPACK_EXTERNAL_MODULE__21f8__, __WEBPACK_EXTERNAL_MODULE__342a__, __WEBPACK_EXTERNAL_MODULE__35c9__, __WEBPACK_EXTERNAL_MODULE__3746__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__4ebd__, __WEBPACK_EXTERNAL_MODULE__574c__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__8aba__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__9463__, __WEBPACK_EXTERNAL_MODULE_a28b__, __WEBPACK_EXTERNAL_MODULE_b87b__, __WEBPACK_EXTERNAL_MODULE_bae4__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d833__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_dffb__, __WEBPACK_EXTERNAL_MODULE_f095__, __WEBPACK_EXTERNAL_MODULE_f4f3__) {
return 