
import Vue from 'vue'

// @ts-ignore
import { VectorLayer, Circle } from 'maptalks'
import { getWidgetMap } from '@/mapStore'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'TCircle',
  props: {
    visible: { type: Boolean, required: false, default: undefined },
    center: Array,
    radius: Number,
    displayZoom: Number,
    options: Object,
    zIndex: Number,
    animate: Boolean
  },
  data: () => ({}),
  computed: {
    ...mapState(['widgetUUID'])
  },
  created() {
    this.static = {
      parentMap: {} as any,
      layer: {} as any,
      circle: {} as any
    }
  },
  watch: {
    options: {
      handler(val) {
        if (val.symbol) {
          this.static.circle.updateSymbol(val.symbol)
        }
      },
      deep: true
    },
    visible(val) {
      if (val) {
        this.static.layer.show()
      } else {
        this.static.layer.hide()
      }
    }
  },
  async mounted() {
    this.static.parentMap = getWidgetMap(this.widgetUUID)
    this.static.circle = new Circle(this.center, this.radius || 100, {
      ...this.options
    })
    this.static.layer = new VectorLayer(`${this._uid}-vector-layer`, this.static.circle, {
      zIndex: this.zIndex || 1,
      visible: this.visible === undefined || this.visible,
      hitDetect: false,
      forceRenderOnZooming: true,
      forceRenderOnMoving: true,
      forceRenderOnRotating: true,
      minZoom: this.displayZoom || 5
    }).addTo(this.static.parentMap)
    this.static.circle.on('click dblclick touchend', () => {
      this.$emit('click')
    })
  },
  destroyed() {
    this.static.parentMap.removeLayer(this.static.layer)
  }
})
