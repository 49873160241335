
import { mapStateTyped } from '@/store'
import { isEqual } from 'lodash'
import Vue from 'vue'

export default Vue.extend({
  name: 'QueryFilters',
  props: {
    value: Object,
    canI: Function,
    entityTypes: Array as () => string[],
    filteredEntities: Array as () => any[]
  },
  data() {
    return {
      typeFilters: null as Filters['typeFilters'] | null,
      qFilters: null as Filters['qFilters'] | null,
      otherFilters: null as Filters['otherFilters'] | null
    }
  },
  computed: {
    ...mapStateTyped(['spaParent', 'spaProps', 'filteredEntitiesSet', 'filteredEntitiesTracker']),
    isEmpty(): boolean {
      return this.filteredEntitiesTracker && this.filteredEntitiesSet.size === 0
    }
  },
  methods: {
    isEqual,
    menuToggle(ref: string, event: Event) {
      let menu = this.$refs[ref] as Vue & {
        toggle: (event: Event) => {}
      }
      menu = Array.isArray(menu) ? menu[0] : menu
      if (menu !== undefined) {
        menu!.toggle(event)
      }
    },
    count(key: string, value: string): string {
      const filtered =
        (this.filteredEntitiesTracker &&
          Array.from(this.filteredEntitiesSet).filter((entity) => {
            return Array.isArray(value)
              ? value.includes(entity[key] as string)
              : entity[key] === value
          })) ||
        []
      return filtered.length > 0 ? String(filtered.length) : ''
    },
    toggleFilter(field: string, filterValue: string | string[], isTypeFilter = false) {
      const isFilterArray = Array.isArray(filterValue)
      let localFilters = this.value[field]
      if (localFilters !== undefined) {
        if (!isFilterArray && localFilters.includes(filterValue)) {
          localFilters.splice(localFilters.indexOf(filterValue), 1)
          // When filter by type is turning off we turn off related filters too
          if (isTypeFilter) {
            const applicableFilters =
              this.qFilters?.filter((filter: any) =>
                filter.applicableToTypes.includes(filterValue)
              ) || []
            applicableFilters.forEach((filters: any) => {
              delete this.value[filters.attributeName]
            })
          }
        } else if (isFilterArray) {
          localFilters = this.isEqual(localFilters, filterValue) ? [] : filterValue
        } else {
          localFilters.push(filterValue)
        }
        this.$set(this.value, field, localFilters)
      } else {
        this.$set(this.value, field, isFilterArray ? filterValue : [filterValue])
      }
      if (this.value[field].length === 0) {
        delete this.value[field]
      }
    },
    checkApplicability(applicableToTypes: string[] | undefined): boolean {
      if (applicableToTypes === undefined) {
        return true
      }
      const b = new Set(applicableToTypes)
      const filters = (this.value.type as string[]) || []
      return filters.length > 0
        ? [...new Set(filters)].filter((x) => !b.has(x)).length === 0
        : false
    }
  },
  mounted() {
    if (this.spaProps.filters !== undefined) {
      this.typeFilters = this.spaProps.filters.typeFilters
      this.qFilters = this.spaProps.filters.qFilters
      this.otherFilters = this.spaProps.filters.otherFilters
    }
  }
})
