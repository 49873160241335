import { mapState, mapMutations } from 'vuex'
import { TUnionRepo } from '@netvision/lib-api-repo'
import { RangeTree } from './RangeTree'

type Mutable<Type> = {
  -readonly [Key in keyof Type]: Type[Key]
}

const savedLayerName = localStorage.getItem('savedLayerName')
const initialState = () =>
  ({
    api: {} as TUnionRepo,
    markersRangeTree: null as RangeTree | null,
    visibleMarkersSet: new Set() as Set<string>,
    visibleMarkersSetTracker: 0,
    widgetUUID: '',
    popupFeatureToggle: true,
    popupMode: 'single' as 'single' | 'multiple',
    openedPopupsIds: [] as string[],
    maxOpenedPopups: 3,
    spaParent: {} as Vue['$parent'],
    spaProps: {} as SPAProps,
    currentLayerName: savedLayerName || 'default',
    allMapEntitiesTree: [] as CompositionEntity[],
    areaEntityTypes: ['ComplexObjectArea'] as string[],
    highlightedIds: [] as string[],
    highlighted: {} as Highlighted,
    leafEntityTypes: ['Camera', 'ComplexObject'] as string[],
    permissionScopes: new Map(),
    analytics: [] as Assignment[],
    assignmentGroups: null as IAssignmentGroup[] | null,
    assignmentTypes: [] as string[],
    currentOpenedComplexObject: {} as ComplexObject,
    isDarkTheme: undefined as boolean | undefined,
    selectedMode: 'canvas' as 'gl' | 'canvas',
    showArchivePlayerContainer: false,
    showAssignmentDialogContainer: false,
    clusteredEntitiesIds: [] as string[],
    currentEntityId: '' as string,
    filteredEntitiesTracker: 1,
    filteredEntitiesIds: new Set() as Set<string>,
    filteredEntitiesSet: new Set() as Set<Camera | BolidDevice | ComplexObject>,
    complexObjectsCache: new Map(),
    clusteringByType: false,
    clusters: {} as Record<string, Cluster[]>,
    clustersLocationMap: new Map() as Map<string, Cluster['location']>,
    clustersLocationMapCount: 0,
    maxClusterRadius: 100,
    clusteredMarkersIds: [] as string[],
    maxZoom: 23,
    minZoom: 5,
    currentZoom: 50,
    maxLabelLength: 20,
    prevRoutes: [] as any,
    fullWidth: 1920,
    fullHeight: 1080,
    allNestedComplexObject: [] as ComplexObject[]
  } as const)

type State = ReturnType<typeof initialState>

export const mapStateTyped = <T extends keyof State, G extends { [Key in T]: () => State[Key] }>(
  keys: T[]
): G => {
  return { ...mapState(keys) } as G
}

const mutations = {
  setValue(state: Mutable<State>, [key, value]: [keyof State, ValueOf<State>]) {
    // @ts-ignore
    state[key] = value
  },
  setAdditionalData(
    state: Mutable<State>,
    [objId, { outline, expandingZoom, layersOrder }]: [
      string,
      Pick<BaseComplexObject, 'outline' | 'expandingZoom' | 'layersOrder'>,
    ]
  ) {
    state.allMapEntitiesTree = state.allMapEntitiesTree.map<CompositionEntity>((obj) =>
      obj.id !== objId ? obj : { ...obj, outline, expandingZoom, layersOrder }
    )
    // const targetObj = state.allMapEntitiesTree.find(({ id }) => id === objId)
    // if (targetObj) {
    //   targetObj.outline = outline
    //   targetObj.expandingZoom = expandingZoom
    //   targetObj.layersOrder = layersOrder
    // }
  },
  togglePopup(state: Mutable<State>, id: string) {
    if (state.openedPopupsIds.includes(id)) {
      state.openedPopupsIds = state.openedPopupsIds.filter((i) => i !== id)
    } else {
      if (state.openedPopupsIds.includes(id)) return
      if (state.popupMode === 'single') {
        state.openedPopupsIds = [id]
        return
      }
      if (state.openedPopupsIds.length === state.maxOpenedPopups) {
        state.openedPopupsIds.shift()
      }
      state.openedPopupsIds.push(id)
    }
  },
  closeAllPopups(state: Mutable<State>) {
    state.openedPopupsIds = []
  },
  reset(state: Mutable<State>) {
    // acquire initial state
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  },
  clickOnMap(state: Mutable<State>) {
    if (state.popupMode === 'single') {
      state.openedPopupsIds = []
    }
  }
} as const
type Mutations = typeof mutations

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  },
>(
  keys: T[]
): G => {
  return mapMutations(keys) as G
}

export default {
  state: initialState(),
  mutations
}
