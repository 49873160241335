
import Vue from 'vue'
import { te } from '@/translations'

export default Vue.extend({
  name: 'YesNoDialog',
  props: {
    show: Boolean,
    message: String,
    header: String
  },
  data() {
    return {
      displayYesNoDialog: this.show
    }
  },
  watch: {
    show(val) {
      this.displayYesNoDialog = val
    },
    displayYesNoDialog() {
      this.$emit('update:show', this.displayYesNoDialog)
    }
  },
  mounted() {
    document.addEventListener('keypress', this.keysHandler)
  },
  destroyed() {
    document.removeEventListener('keypress', this.keysHandler)
  },
  methods: {
    te,
    keysHandler(event: KeyboardEvent) {
      if (!this.displayYesNoDialog) return
      if (event.key === 'Enter') {
        this.$emit('onYes')
        setTimeout(() => {
          this.displayYesNoDialog = false
        })
      }
    }
  }
})
