import { isArray } from 'lodash'

export const insensitify = (str: string) =>
  str
    .split('')
    .map((char) => `[${char.toLowerCase()}${char.toUpperCase()}]`)
    .join('')
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  }) as Uuid
}

/**
 * Build composited tree from all objects into node objects (cameras in complex object for example)
 * @param listOfEntities array of all entities
 * @param nodeTypes names of node objects types
 */
export const makeObjectsTree = (listOfEntities: IMapEntity[], nodeTypes: string[]) => {
  const mapOfEntities = new Map(
    listOfEntities.map((entity) => [entity.id, entity as CompositionEntity & IMapEntity])
  )
  // put childrens in parents back (=
  mapOfEntities.forEach((entity) => {
    if (!entity.servicePath) return
    const nodeEntity = mapOfEntities.get(entity.servicePath)
    if (nodeEntity?.type && nodeTypes.includes(nodeEntity?.type)) {
      nodeEntity.childrens = [...(nodeEntity.childrens || []), entity]
    }
  })
  // clear all not root objects, they don't need there
  return [...mapOfEntities.entries()]
    .filter(([_, entity]) => !entity.servicePath)
    .map(([_, e]) => e)
}

const timers = {} as any
export const debounce = (callback: Function, timeout: number, key: string = 'main') => {
  clearTimeout(timers[key])
  timers[key] = setTimeout(callback, timeout)
}

const queues = {} as Record<string, (() => void)[]>

const startSetTimeoutQueue = async (key: string, timout: number) => {
  do {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
        queues?.[key].pop()?.()
      }, timout)
    })
  } while (queues?.[key].length)
}

export const throttle = (callback: () => void, timeout: number, key: string = 'main') => {
  const queue = queues[key]
  if (isArray(queue)) {
    queue.push(callback)
    queue.length === 1 && startSetTimeoutQueue(key, timeout)
  } else {
    queues[key] = [callback]
    startSetTimeoutQueue(key, timeout)
  }
}

export const unbreakable = (str: string, maxLength = 25) => {
  if (!str) return
  let unbreakableStr = str
  if (unbreakableStr.length > maxLength) {
    unbreakableStr = unbreakableStr.slice(0, maxLength) + '...'
  } else {
    unbreakableStr = str.replace(' ', '\xa0').replace('-', '\u{2011}')
  }
  return unbreakableStr
}
export const breakIntoPieces = (str: string, maxLength = 25): string => {
  if (!str) return ''
  const rowsNumber = Math.ceil(str.length / maxLength)
  const words = str.split(' ')
  if (words.length === 1) {
    return words[0]
  }
  const wordsInRow = words.length / rowsNumber
  const rows = [] as string[]
  let row = [] as string[]

  while (words.length > 0) {
    row.push(words.shift() || '')
    if (row.length >= wordsInRow) {
      rows.push(row.join('\xa0'))
      row = []
    }
  }
  rows.push(row.join('\xa0'))
  return rows.join(' ')
}

export const coordinatesTolocation = (coordinates: [number, number]) => {
  return `${coordinates[1].toFixed(8)}, ${coordinates[0].toFixed(8)}`
}
export const locationToCoordinates = (location: string) => {
  try {
    return location
      ? [Number.parseFloat(location.split(',')[1]), Number.parseFloat(location.split(',')[0])]
      : []
  } catch (error) {
    console.error(error)
    return null
  }
}
export const toNumberOrNaN = (value?: string | number) => {
  if (!value) return NaN
  return Number(value)
}
