import { locationToCoordinates } from './utils'

export interface Point {
  id: string
  x: number
  y: number
}

export interface Range {
  xmin: number
  xmax: number
  ymin: number
  ymax: number
}

export interface TreeNode {
  point: Point
  left: TreeNode | null
  right: TreeNode | null
}

export class RangeTree {
  #points: Point[]
  #tree: TreeNode | null

  constructor(points: IMapEntity[]) {
    this.#points = points
      .reduce<Point[]>((acc, { location, id }) => {
        if (location?.length) {
          const [x, y] = locationToCoordinates(location) || [0, 0]
          acc.push({ id, x, y })
        }
        return acc
      }, [])
      .sort((a, b) => a.x - b.x)
    this.#tree = this.buildTree(this.#points)
  }

  private buildTree(points: Point[]): TreeNode | null {
    if (points.length === 0) return null
    if (points.length === 1) return { point: points[0], left: null, right: null }

    const midIndex = Math.floor(points.length / 2)
    const node: TreeNode = {
      point: points[midIndex],
      left: this.buildTree(points.slice(0, midIndex)),
      right: this.buildTree(points.slice(midIndex + 1))
    }
    return node
  }

  queryRange(range: Range): Set<string> {
    const result: Set<string> = new Set()
    this.queryRangeHelper(this.#tree, range, result)
    return result
  }

  queryRangeHelper(node: TreeNode | null, range: Range, result: Set<string>): void {
    if (!node) return

    if (node.point.x < range.xmin) {
      this.queryRangeHelper(node.right, range, result)
    } else if (node.point.x > range.xmax) {
      this.queryRangeHelper(node.left, range, result)
    } else if (node.point.y < range.ymin || node.point.y > range.ymax) {
      // The point is within the x-range but outside the y-range.
      // Check both subtrees.
      this.queryRangeHelper(node.left, range, result)
      this.queryRangeHelper(node.right, range, result)
    } else {
      // The point is within the range.
      result.add(node.point.id)
      this.queryRangeHelper(node.left, range, result)
      this.queryRangeHelper(node.right, range, result)
    }
  }
}
