
import { uuid } from '@/utils'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import Vue from 'vue'
import YesNoDialog from './YesNoDialog.vue'
import { te } from '@/translations'

export default Vue.extend({
  name: 'CreateLayoutButton',
  components: {
    YesNoDialog
  },
  props: {
    ids: Array as () => string[],
    title: String,
    message: String,
    tooltip: String,
    iconOnly: Boolean,
    isLayer: Boolean
  },
  data() {
    return {
      displayCreateDialog: false,
      loadingStatus: 'init' as FetchingStatus
    }
  },
  computed: {
    icon(): string {
      if (this.loadingStatus === 'loading') {
        return 'mdi-spin mdi-loading'
      }
      return this.isLayer ? 'mdi-group' : 'mdi-view-grid'
    }
  },
  methods: {
    te,
    async createAndOpenLayout() {
      const id = uuid()
      try {
        await createCamerasConnection().v2.createEntity(
          {
            type: 'CamerasLayout',
            id,
            layoutType: '1x1',
            camerasParents: this.ids,
            name: this.title || '',
            cameras: []
          },
          {
            keyValues: true
          }
        )
        setTimeout(() => {
          window.open(`/layouts?currentCamerasLayoutId="${id}"`)
        })
        this.$emit('end')
      } catch (error) {
        console.error(error)
      }
    }
  }
})
