var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Dialog',{style:({ 'min-width': '30vh', 'max-width': '40vw' }),attrs:{"header":_vm.header,"visible":_vm.displayYesNoDialog,"position":"center","dismissableMask":true,"closable":false,"modal":true},on:{"update:visible":function($event){_vm.displayYesNoDialog=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{attrs:{"label":_vm.te('button.yes')},on:{"click":() => {
          _vm.$emit('onYes')
          _vm.displayYesNoDialog = false
        }}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined p-mr-1",attrs:{"label":_vm.te('button.no')},on:{"click":() => {
          _vm.$emit('onNo')
          _vm.displayYesNoDialog = false
        }}})]},proxy:true}])},[_c('div',{staticClass:"p-mx-3"},[_c('span',{domProps:{"textContent":_vm._s(_vm.message)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }