var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.entity.location)?_c('TMarker',_vm._b({key:_vm.entity.id,attrs:{"id":_vm.entity.id,"isVisible":!_vm.isComplexObjectSelected && _vm.isVisible,"centerizeOnClick":"","clusterName":_vm.clusterName,"icon":_vm.entity.iconClass,"tooltip":_vm.entity.title,"color":_vm.color,"transparent":_vm.isTransparent,"tipTooltip":_vm.tipTooltip,"tipColor":_vm.tipTooltip ? 'red' : null,"location":_vm.locationToCoordinates(_vm.entity.location)},on:{"showPopup":_vm.switchPopup},scopedSlots:_vm._u([{key:"popup",fn:function(){return [(_vm.filteredEntitiesTracker)?_c('div',[(_vm.showPopup)?_c('div',{staticClass:"popup"},[(_vm.entity.type === 'Camera')?_c('CameraCard',{attrs:{"camera":_vm.entity,"options":{
              isCompactView: true,
              toast: _vm.$toast,
              isMapView: true
            }}}):_vm._e()],1):_vm._e()]):_vm._e()]},proxy:true}],null,false,1218671950)},'TMarker',_vm.options,false)):_vm._e(),(_vm.entity.location && _vm.entity.directionMinClock !== null)?_c('div',[_c('TSector',{attrs:{"center":_vm.locationToCoordinates(_vm.entity.location),"radius":15,"startAngle":_vm.entity?.directionMaxClock,"endAngle":_vm.entity?.directionMinClock,"options":{
        symbol: {
          opacity: _vm.currentZoom > 17 && !_vm.isComplexObjectSelected && !_vm.isTransparent ? 1 : 0,
          lineWidth: 0,
          polygonFill: {
            type: 'linear',
            colorStops: [
              [1, 'rgba(0, 0, 0, 0.1)'],
              [0, _vm.color]
            ]
          }
        }
      }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }