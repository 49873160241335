
import Vue from 'vue'
// @ts-ignore
import { VectorLayer, MultiPolygon, animation } from 'maptalks'
import { getWidgetMap } from '@/mapStore'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'TPolygon',
  props: {
    points: Array,
    minZoom: Number,
    maxZoom: Number,
    zIndex: Number,
    options: Object,
    fadingAnimation: Boolean,
    visible: { type: Boolean, required: false, default: undefined }
  },
  data() {
    return {
      forward: true
    }
  },
  created() {
    this.static = {
      parentMap: {} as any,
      player: {} as any,
      layer: {} as any,
      polygon: {} as any
    }
  },
  computed: {
    ...mapState(['widgetUUID'])
  },
  watch: {
    options: {
      handler(val) {
        if (val.symbol) {
          this.static.polygon.updateSymbol(val.symbol)
        }
      },
      deep: true
    },
    visible(val) {
      if (val) {
        this.static.polygon.show()
        if (this.fadingAnimation) {
          this.static.player.play()
        }
      } else {
        this.static.polygon.hide()
        if (this.fadingAnimation) {
          this.static.player.pause()
        }
      }
    }
  },
  async mounted() {
    this.static.parentMap = getWidgetMap(this.widgetUUID)
    this.static.polygon = new MultiPolygon([this.points], { ...this.options })
    const layerName = `${this.minZoom ?? '-1'}-vector-layer${this.maxZoom ?? '-1'}`
    this.static.layer = this.static.parentMap.getLayer(layerName)
    if (this.static.layer) {
      this.static.layer.addGeometry(this.static.polygon)
    } else {
      this.static.layer = new VectorLayer(layerName, this.static.polygon, {
        zIndex: this.zIndex ?? 1,
        minZoom: this.minZoom ?? -1,
        hitDetect: false,
        forceRenderOnZooming: true,
        forceRenderOnMoving: true,
        forceRenderOnRotating: true
      }).addTo(this.static.parentMap)
    }
    if (!(this.visible !== undefined ? this.visible : true)) {
      this.static.polygon.hide()
    }

    this.static.polygon.on('click touchend', () => {
      this.$emit('click')
    })
    this.static.polygon.on('dblclick', () => {
      this.$emit('dblclick')
    })

    this.static.polygon.on('mouseenter mouseout', ({ type }: any) => {
      this.$emit(type)
    })

    if (this.fadingAnimation) {
      this.static.player = animation.Animation.animate(
        { symbol: { polygonOpacity: 0.5 } },
        {
          duration: 500,
          easing: 'out',
          repeat: true
        },
        (frame: any) => {
          if (frame.state.playState === 'running') {
            if (frame.styles.symbol.polygonOpacity === 0) {
              this.forward = !this.forward
            }
            frame.styles.symbol.polygonOpacity = this.forward
              ? frame.styles.symbol.polygonOpacity + 0.1
              : 0.6 - frame.styles.symbol.polygonOpacity
            this.static.polygon.updateSymbol(frame.styles.symbol)
          }
        }
      )
      if (this.visible) {
        this.static.player.play()
      }
    }
  },
  destroyed() {
    this.static.parentMap.removeLayer(this.static.layer)
  }
})
