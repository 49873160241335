import { STRING_QUOTE } from './constants'
export const removeQuotes = (s: string): string => s.replace(new RegExp(STRING_QUOTE, 'g'), '')
export const addQuotes = (s: string): string => [STRING_QUOTE, s, STRING_QUOTE].join('')
export const isUndefined = (v: unknown): v is undefined => typeof v === 'undefined'
export const isString = <T extends string>(v: unknown): v is T => typeof v === 'string'
export const isNumber = <T extends number>(v: unknown): v is T => typeof v === 'number'
export const isBoolean = <T extends boolean>(v: unknown): v is T => typeof v === 'boolean'
export const splitWith = (s: string, delimiter: string, quote: string): string[] => {
  const indexes = []
  let isEscaped = false
  for (let i = 0; i < s.length; i++) {
    if (s[i] === quote) {
      isEscaped = !isEscaped
    } else if (s[i] === delimiter && !isEscaped) {
      indexes.push(i)
    }
  }
  if (indexes.length === 0) {
    return [s]
  } else {
    return indexes.reduce((acc, value, index, arr) => {
      if (index === 0) {
        acc.push(s.slice(0, value))
      }
      if (index === arr.length - 1) {
        acc.push(s.slice(value + 1))
      } else {
        acc.push(s.slice(value + 1, arr[index + 1]))
      }
      return acc
    }, [] as string[])
  }
}
