import './set-public-path'
import Vue from 'vue'

import singleSpaVue from 'single-spa-vue'
import VueRouter from 'vue-router'
import Toast from 'primevue/toast'
import Vuex from 'vuex'
import Store from './store'
// @ts-ignore
import VueResizeObserver from 'vue-resize-observer'
import ToastService from 'primevue/toastservice'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import SelectButton from 'primevue/selectbutton'
import ProgressSpinner from 'primevue/progressspinner'
import Dialog from 'primevue/dialog'
import Slider from 'primevue/slider'
import Tooltip from 'primevue/tooltip'
import OverlayPanel from 'primevue/overlaypanel'
import CameraCard from './components/common/CameraCard.vue'
import BolidDeviceCard from './components/common/BolidDeviceCard.vue'
import MarkerCircle from './components/common/MarkerCircle.vue'
import ComplexObjectOnMap from './components/ComplexObjectOnMap.vue'
import ScrollPanel from 'primevue/scrollpanel'
import PanelMenu from 'primevue/panelmenu'
import ProgressBar from 'primevue/progressbar'
import AsyncComputed from 'vue-async-computed'

import App from './App.vue'
Vue.prototype.$eventBus = new Vue()
Vue.prototype.$maptalks = {} as any

Vue.component('Toast', Toast)
Vue.use(ToastService)
Vue.component('Dropdown', Dropdown)
Vue.component('InputText', InputText)
Vue.component('Button', Button)
Vue.component('SelectButton', SelectButton)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('Dialog', Dialog)
Vue.component('Slider', Slider)
Vue.directive('tooltip', Tooltip)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('CameraCard', CameraCard)
Vue.component('BolidDeviceCard', BolidDeviceCard)
Vue.component('MarkerCircle', MarkerCircle)
Vue.component('ComplexObjectOnMap', ComplexObjectOnMap)
Vue.component('ScrollPanel', ScrollPanel)
Vue.component('PanelMenu', PanelMenu)
Vue.component('ProgressBar', ProgressBar)

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueResizeObserver)
Vue.use(AsyncComputed)

Vue.config.productionTip = false
const routes = [] as any
const router = new VueRouter({
  base: __dirname,
  mode: 'history',
  routes
})

const store = new Vuex.Store(Store)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    store,
    render: (h: any) => h(App)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
