
import Vue from 'vue'
import { mapStateTyped } from '@/store'
import { breakIntoPieces, locationToCoordinates } from '@/utils'
import TMarker from './TMarker.vue'
import TSector from './TSector.vue'
import { te } from '@/translations'

export default Vue.extend({
  name: 'EntityMarker',
  components: { TMarker, TSector },
  props: {
    entity: {
      type: Object as () => Camera | BolidDevice | ComplexObject,
      required: true
    },
    clusterName: String,
    canBeTransparent: {
      type: Boolean,
      required: false,
      default: true
    },
    options: {
      type: Object as () => Record<string, number | string>,
      required: false
    }
  },
  data: () => {
    return {
      showPopup: false
    }
  },
  computed: {
    ...mapStateTyped([
      'spaProps',
      'currentOpenedComplexObject',
      'highlighted',
      'highlightedIds',
      'filteredEntitiesTracker',
      'filteredEntitiesIds',
      'currentZoom',
      'currentEntityId'
    ]),
    tipTooltip(): string | null {
      const { totalNotWorkingStreamCount: notWorking, totalStreamCount: total } = this.entity || {}
      return total && notWorking && notWorking !== '0'
        ? `${notWorking} ${te('from')} ${total} ${te('message.streamsNotWorking')}`
        : null
    },
    isComplexObjectSelected(): Boolean {
      return Object.keys(this.currentOpenedComplexObject)?.length !== 0
    },
    color(): string {
      return this.highlighted[this.entity.id]?.color || this.entity?.iconColor || '#3c72ff'
    },
    isVisible(): boolean {
      return this.filteredEntitiesIds.has(this.entity.id)
    },
    isTransparent(): boolean {
      return (
        this.canBeTransparent &&
        this.highlightedIds.length !== 0 &&
        !this.highlighted[this.entity.id]
      )
    }
  },
  methods: {
    switchPopup(val: boolean): void {
      setTimeout(() => (this.showPopup = val))
    },
    te,
    breakIntoPieces,
    locationToCoordinates
  }
})
