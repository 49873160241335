var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"marker-container"},[_c('div',{staticClass:"marker__circle-background",style:({
      backgroundColor: _vm.color,
      opacity: _vm.isDarkTheme ? '0.5' : '1',
      '--thisColor': _vm.color,
    })}),_c('div',{staticClass:"marker__circle",style:({
      border: `1px solid ${_vm.color}`,
    })},[_vm._t("default")],2),(_vm.haveLeg && _vm.tallLeg)?_c('div',{staticClass:"marker__leg_tall",style:(`background-image: linear-gradient(to bottom, ${_vm.color}, rgba(255,255,255,0));`)}):_vm._e(),(_vm.haveLeg && !_vm.tallLeg)?_c('div',{staticClass:"marker__leg",style:(`background-image: linear-gradient(to bottom, ${_vm.color}, rgba(255,255,255,0));`)}):_vm._e(),(_vm.haveLeg && !_vm.tallLeg)?_c('div',{staticClass:"marker__pointer"},[_c('div',{staticClass:"marker__shadow",style:({
        backgroundColor: _vm.color,
        opacity: '0.4',
      })}),_c('div',{staticClass:"marker__dot",style:({
        backgroundColor: _vm.color,
      })})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }